import {useEffect, useState} from "react";
import Page from "../layouts/Page";
import '../../assets/css/longans/create_longan_tree.css'
import greenTreeIcon from "../../assets/images/tree-icon-green.png"
import InputImage from "../../components/InputImage";
import checkCloudImg from "../../assets/images/cloud-check.png"
import {value} from "lodash/seq";
import {createLongan, getLonganDetail, uploadLonganTreePicture} from "../../api/LonganApi";
import {useNavigate, useParams} from "react-router-dom";
import {alertError, alertSuccess, confirmPopupHTML, dismissPopup, showProgress} from "../../components/AlertDialogs";
import ModalPackageWarning from "./ModalPackageWarning";
import {checkMaxPackage} from "../../api/FarmerApi";
import {store} from "../../redux/store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import ModalGoogleMap from "../../components/ModalGoogleMap";
import {getFarmDetail} from "../../api/FarmsApi";
import async from "async";

function CreateLonganTree() {
  const [data, setLonganData] = useState({name: '', specie: 'Edaw', pictures: [], type: 'TRIMMING'});
  const params = useParams();
  const navigate = useNavigate();
  const [showPackage, setShowPackage] = useState(false);
  const [showMap, setShowMap] = useState(false)
  const [farms, setFarm] = useState(null)
  const [removesId, setRemoveFilesId] = useState([]);

  useEffect(() => {
    if(params.longan_id) {
      getLonganDetail(params.longan_id)
        .then((res) => {
          if(res.data && res.data.success) {
            setLonganData(res.data.data)
          }
        })
    }else {
      checkPackage()
    }

    getFarmData()
  }, []);

  const getFarmData = () => {
    getFarmDetail(params.farm_id ? params.farm_id : params.id)
      .then(res => {
        if(res.data && res.data.success) {
          setFarm(res.data.data)
        }
      })
  }

  const checkPackage = () => {
    const user = store.getState() && store.getState().app && store.getState().app.user
    const farmer_id = user && user.type === 'ADMIN' ? params && params.id : user && user.id;
    checkMaxPackage(farmer_id)
      .then(res => {
        if(res.data && res.data.success) {
          setShowPackage(res.data.data.is_over_package);
        }
      })
  }

  const closeModalPackage = () => {
    setShowPackage(false)
    navigate(-1)
  }

  const longanImages = [
    {label: 'รูปต้นลำไย ทิศเหนือ', name: '', directions: 'north'},
    {label: 'รูปต้นลำไย ทิศใต้', name: '', directions: 'south'},
    {label: 'รูปต้นลำไย ทิศตะวันออก', name: '', directions: 'east'},
    {label: 'รูปต้นลำไย ทิศตะวันตก', name: '', directions: 'west'},
    {label: 'รูปต้นลำไย มุมบน', name: '', directions: 'top'},
  ]

  const LonganSpecies = [
    { label: 'พันธ์ุอีดอ', value: 'Edaw'},
    { label: 'พันธ์ุเบียวเขียว', value: 'Biaokhiao'},
    { label: 'พันธ์ุชมพู', value: 'Chomphoo'},
    { label: 'พันธ์ุอื่น ๆ', value: 'Other'}
  ];

  const submit = async () => {
    showProgress('')
    const user = store.getState() && store.getState().app && store.getState().app.user
    const pictures = data.pictures;
    data.farmer_id = user && user.type === 'ADMIN' ? params && params.id : user && user.id;
    data.farm_id = user && user.type === 'ADMIN' ? params && params.farm_id : params && params.id;
    data.removesId = removesId;
    createLongan(data, params && params.longan_id)
      .then(res => {
        if(res.data && res.data.success){
          alertSuccess()
          setTimeout(() => {
            navigate(-1)
          }, 800)

          /*pictures.map(async (pic) => {
            if(pic.file) {
              uploadLonganTreePicture(pic, res.data.data.id)
                .then(res => {

                })
            }
          })*/

        }else {
          let message = (res.data && res.data.message) || ''
          alertError('บันทึกข้อมูลไม่สำเร็จ', message)
        }
    })
  }

  const removeFile = (directions, seq) => {
    let picture_id = null;
    data.pictures.map((pic, index) => {
      if(pic.directions === directions && pic.seq == seq) {
        if(params.longan_id) {
          picture_id = data.pictures[index].id;
        }
        data.pictures.splice(index, 1);
      }
      return data.pictures;
    })

    if(picture_id) {
      console.log('picture_id >>> ', picture_id)
      removesId.push(picture_id)
      setRemoveFilesId(removesId)
    }
  }

  const setPictureData = (directions, seq, file) => {
    let pictures = data.pictures;
    let items = {
      directions,
      file,
      seq
    }
    pictures.push(items)

    setLonganData(exitingData => ({
      ...exitingData,
      pictures: pictures
    }))
  }

  const setData = (key, value) => {
    setLonganData(exitingData => ({
      ...exitingData,
      [key]: value
    }))
  }

  const getPicture = (directions, seq) => {
    let picture = null;
    if(data && data.pictures && data.pictures.length > 0) {
      data.pictures.map((pic) => {
        if(pic.directions === directions && pic.seq === seq) {
          picture = pic.full_picture_path
        }
      })
    }

    return picture;
  }

  const handleShowMap = () => {
    if(data && (!data.name || data.name == '')) {
      alertError('', 'กรุณากรอกชื่อต้นลำไยก่อนเลือกพิกัดต้นลำไย')
      return
    }

    setShowMap(true)
  }

  return (
    <Page header={{title: params.longan_id ? data.name : 'เพิ่มต้นลำไย', showLeftSide: true}}>
      <div className="create-longan-tree-container">
        <div className="mb-3">
          <div className="mb-3">ชื่อต้นลำไย</div>
          <input className="input-border-light form-control"
                 value={data && data.name}
                 placeholder="ระบุชื่อต้นลำไย"
                 name="name"
                 onChange={(e) => setData(e.target.name, e.target.value)}
          />
        </div>

        <div className="mb-3">
          <div className="mb-3">พิกัดต้นลำไย</div>
          <div className="input-group">
            <input type="email" className="form-control input-border-light"
                   placeholder="เลือกพิกัดต้นลำไย"
                   aria-label="pin_location"
                   name="location"
                   // onChange={(e) => setData(e.target.name, e.target.value)}
                   value={data && data.location}
                   disabled
                   aria-describedby="pin_location"/>
            <span className="input-group-text"
                  onClick={() => handleShowMap()}
                  id="pin_location">
                  <FontAwesomeIcon icon={faLocationDot} className="main-color" />
              </span>
          </div>
        </div>

        <div className="mb-3">
          <div className="mb-3">ชนิดพันธุ์</div>
          <select name="specie" id="specie"
                  placeholder="เลือกชนิดพันธุ์"
                  onChange={(e) => setData(e.target.name, e.target.value)}
                  className="form-select">
            {
              LonganSpecies.map((specie,index) => (
                <option value={specie.value} key={`specie-option-${index}`} selected={specie.value === data.specie}>{specie.label}</option>
              ))
            }
          </select>
        </div>

        <div className="mb-3">
          <div className="mb-3">รูปต้นลำไย</div>
        </div>

        {
          longanImages.map((longan, index) => (
            <div className="mb-3 image-longan-box" key={`image-longan-${index}`}>
              <div>
                <div className="d-flex align-items-center" style={{color: '#004B8A'}}>
                  <img src={greenTreeIcon} alt="green tree icon"/>&nbsp;
                  {longan.label}
                </div>

                <div className="flex-1 d-flex mb-3 mt-3">
                  <div className="flex-1 me-2">
                    <InputImage id={`${longan.directions}-1`}
                                setFile={(file) => setPictureData(longan.directions, 1, file)}
                                removeFile={() => removeFile(longan.directions, 1)}
                                value={getPicture(longan.directions, 1)}
                    />
                  </div>
                  <div className="flex-1">
                    <InputImage id={`${longan.directions}-2`}
                                setFile={(file) => setPictureData(longan.directions, 2, file)}
                                // value={longan && longan.full_picture_path}
                                removeFile={() => removeFile(longan.directions, 2)}
                                value={getPicture(longan.directions, 2)}
                    />
                  </div>
                </div>

                <div className="flex-1 d-flex mb-3 mt-3">
                  <div className="flex-1 me-2">
                    <InputImage id={`${longan.directions}-3`}
                                setFile={(file) => setPictureData(longan.directions, 3, file)}
                                removeFile={() => removeFile(longan.directions, 3)}
                                value={getPicture(longan.directions, 3)}
                    />
                  </div>
                  <div className="flex-1">
                    <InputImage id={`${longan.directions}-4`}
                                setFile={(file) => setPictureData(longan.directions, 4, file)}
                                // value={longan && longan.full_picture_path}
                                removeFile={() => removeFile(longan.directions, 4)}
                                value={getPicture(longan.directions, 4)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))
        }

        {/*<div className="mb-2 image-longan-box">
          <div className="d-flex align-items-center mb-2" style={{color: '#004B8A'}}>
            <img src={greenTreeIcon} alt="green tree icon"/>&nbsp;
            รูปต้นลำไย มุมบน
          </div>

          <InputImage id="top"
                      setFile={(file) => setPictureData('top', 0, file)}
                      removeFile={() => removeFile('top', 0)}
                      value={getPicture('top', 0)}
          />
        </div>*/}

        <div className="mt-3 w-100">
          <button className="btn next-btn w-100" onClick={() => submit()}>
            <img src={checkCloudImg} alt="icon button submit" className="me-2"/>
            ตกลง
          </button>
        </div>
      </div>

      <ModalPackageWarning modalIsOpen={showPackage}
                           closeModal={() => closeModalPackage()}/>

      {
        showMap && <ModalGoogleMap modalIsOpen={showMap}
                                   setLocation={(place_name) => setData('location', place_name)}
                                   closeModal={() => setShowMap(false)}
                                   propPolygons={farms && farms.polygons}
                                   drawingModes={['marker']}
                                   propMarker={data && data.location}
                                   propMarkerName={data.name}
        />
      }
    </Page>
  )
}

export default CreateLonganTree;
