import Page from "../layouts/Page";
import '../../assets/css/productivity/productivity.css';
import predictImage from "../../assets/images/predict.png"
import {useEffect, useState} from "react";
import {dismissPopup, showProgress} from "../../components/AlertDialogs";
import {getFarmList} from "../../api/FarmsApi";
import {useSelector} from "react-redux";
import defaultTreeImg from "../../assets/images/farm-not-found.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot, faTrash} from "@fortawesome/free-solid-svg-icons";
import TreeIconGreen from "../../assets/images/tree-icon-green.png";
import OliveIconGreen from "../../assets/images/olive-branch.png";
import {useNavigate} from "react-router-dom";
function Productivity() {
  const [farms, setFarms] = useState([]);
  const navigate = useNavigate();

  const app = useSelector(state => {
    return state && state.app && state.app
  })

  useEffect(() => {
    showProgress('')
    getFarms()
  }, []);

  const getFarms = () => {
    let farmer_id = app.farmer_id ? app.farmer_id : app.user_id
    getFarmList(farmer_id)
      .then(res => {
        if (res.data && res.data.success) {
          setFarms(res.data.data)
        }
      }).finally(res => {
      dismissPopup()
    })
  }

  return (
    <Page header={{title: 'ปริมาณผลผลิต', showRightSide: true}}
          footer={{}}
    >
      <div className="productivity-container" style={farms && farms.length > 0 ? {} : {display: 'grid'}}>
        <div className="main-blue-color d-flex mb-2 align-items-center align-self-start">
          <div className="vertical-line me-1"></div>
          ปริมาณผลผลิต
        </div>

        {
          farms && farms.length > 0 ?
            <div className="farms-container">
              <div className="farms-list-container h-100">
                {

                  farms.map((farm, index) => (
                    <div className="farm-box" key={`farms-${index}`} onClick={() => navigate(`/productivity/${farm.id}`)}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center w-100">
                          <div style={{width: '30%', marginRight: 10}}>
                            <div className="farm-img-box">
                              <img src={farm.full_picture_path || defaultTreeImg} alt="longan image"
                                   className="w-100 h-100"/>
                            </div>
                          </div>

                          <div style={{padding: '0.8rem', width: '70%'}} className="text-left">
                            <div>
                              <div className="fw-bold mb-2">
                                <p className="mb-0 text-nowrap overflow-hidden text-ellipsis">
                                  {farm.name}
                                </p>
                              </div>

                              <div style={{fontSize: 12}} className="mb-2">
                                <FontAwesomeIcon icon={faLocationDot} className="main-color" style={{color: '#ED2227'}}/>
                                <span className="ms-2">{farm.location || '-'}</span>
                              </div>

                              <div className="d-flex align-items-center">
                                <div className="farm-detail d-flex align-items-center me-1" style={{fontSize: 10, background: 'white'}}>
                                  <img src={TreeIconGreen} alt={'icon tree'} className="me-1"/>
                                  <span style={{fontSize: 12}}> {farm.amount_of_tree} ต้น </span>
                                </div>

                                <div className="farm-detail d-flex align-items-center" style={{fontSize: 10}}>
                                  <img src={OliveIconGreen} alt={'icon tree'} className="me-1"/>
                                  <span style={{fontSize: 12}}> {parseFloat(farm.productivity).toLocaleString()} กก. </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            :
            <div>
              <img src={predictImage} alt="predict image"/>
              <div className="main-green-color fw-bold" style={{fontSize: 20}}>
                ยังไม่มีข้อมูลปริมาณผลผลิต
              </div>
            </div>
        }
      </div>
    </Page>
  )
}

export default Productivity;
