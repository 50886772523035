import {Modal, ModalBody} from "reactstrap";
import ImagePreview from "../../components/ImagePreview";
import buyPackageIcon from "../../assets/images/icon-buy-package.png";
import packageImg from "../../assets/images/package.png";
import rejectPackageIcon from "../../assets/images/icon-reject-package.png"
import approveIcon from "../../assets/images/clipboard-check.png"
import rejectIcon from "../../assets/images/clipboard-reject.png"
import cloudCheck from "../../assets/images/cloud-check.png";
import {alertError, alertSuccess, dismissPopup, showProgress} from "../../components/AlertDialogs";
import {useNavigate} from "react-router-dom";
import {approveOrRejectPackage} from "../../api/PackageApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const ModalConfirmApprovePackage = ({modalIsOpen, closeModal, farmer, packageData, isApprove, item}) => {
  const navigate = useNavigate();
  const approvePackage = () => {
    showProgress('')
    let data = {
      is_approve: isApprove,
      id: item && item.reference && item.reference.id
    }
    approveOrRejectPackage(data)
      .then(res => {
        if(res.data && res.data.success) {
          alertSuccess('ทำรายการสำเร็จ')
          setTimeout(() => {
            navigate(-1)
          }, 800)
        }else {
          alertError('ทำรายการไม่สำเร็จ')
        }
      })
  }

  return (
    <Modal isOpen={modalIsOpen}
           centered={true}
           scrollable={true}
           fade={true}
    >
      <div className="p-3 position-relative">
        <ModalBody>
          <div onClick={() => closeModal()} style={{position: 'absolute', right: 0, top: 0}}>
            <FontAwesomeIcon icon={faTimes}/>
          </div>
          <div className="d-flex align-items-center justify-content-center" style={{height: '20%'}}>
            <div className="text-center">
              <ImagePreview src={farmer && farmer.full_picture_path}
                            styleImgBox={{width: 88, height: 88, border: isApprove ? '4px solid #008F44' : '4px solid #ED2227'}}
              />
              <div className="mt-2">{farmer && farmer.name}</div>
            </div>

            &emsp;
            <img src={isApprove ? buyPackageIcon : rejectPackageIcon} className="me-3"/>

            <div className="text-center">
              <img src={packageImg}/>
              <div className="mt-2">{packageData && packageData.name}</div>
            </div>
          </div>

          <div className="w-100 d-flex justify-content-center mt-3">
            <div
              className="p-2 rounded-5"
              style={
                isApprove
                  ? {background: '#DEF7EC', color: '#028C41', fontSize: 18}
                  : {background: '#FDD1D9', color: '#ED2227', fontSize: 18}
              }>
              {isApprove ? 'อนุมัติการซื้อแพ็กเกจ' : 'ไม่อนุมัติการซื้อแพ็กเกจ'}
              <img src={isApprove ? approveIcon : rejectIcon} className="ms-2"/>
            </div>
          </div>

          <div className="text-center mt-3 rounded p-2"
               style={{background: isApprove ? '#F1FFF9' : '#FFF1F1' }}>
            {`กดปุ่มยืนยัน เพื่อ${!isApprove ? 'ไม่' : ''}อนุมัติการซื้อแพ็กเกจ สำหรับจัดการสวนลำไยของเกษตรกร`}
          </div>

          <button className="btn btn-approve-package w-100 mt-3" onClick={() => approvePackage()}>
            <img src={cloudCheck} className="me-2"/>
            ยืนยัน
          </button>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ModalConfirmApprovePackage;
