import Page from "../layouts/Page";
import diagnoseLonganTreeImg from "../../assets/images/dianose-longan-tree.png"
import droneLonganImg from "../../assets/images/drone-image.png"
import '../../assets/css/longans/drone.css'
import phoneVolumeIcon from "../../assets/images/phone-volume.png"
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getLonganDetail} from "../../api/LonganApi";
import {dismissPopup, showProgress} from "../../components/AlertDialogs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLine} from "@fortawesome/free-brands-svg-icons";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
function LonganTreeDrone() {
  const routeParams = useParams();
  const [longan, setLongan] = useState();
  const navigate = useNavigate();

  const user = useSelector(state => {
    return state.app && state.app.user;
  })

  useEffect(() => {
    let longanId = routeParams.id || null;

    if(longanId) {
      showProgress('')
      getLonganDetail(longanId)
        .then(res => {
          if(res.data && res.data.success) {
            setLongan(res.data.data)
          }
        }).finally(() => {
          dismissPopup()
      })
    }
  }, []);

  return (
    <Page header={{title: (longan && longan.name) || 'OPEN INNOVATION', showLeftSide: true}}>
     <div className="drone-container d-flex flex-column">
       <div className="d-flex flex-column flex-basis-65">
         <div className="h-100 d-grid">
           <div className="main-blue-color d-flex mb-2 align-items-center align-self-start">
             <div className="vertical-line me-1"></div>
             การวิเคราะห์การตัดแต่งช่อผล
           </div>

           <div className="d-flex align-items-center justify-content-center flex-column">
             <img src={diagnoseLonganTreeImg} alt="diagnose image"/>
             <div className="main-green-color fw-bold mt-3" style={{fontSize: 20}}>ยังไม่มีการวิเคราะห์การตัดแต่งช่อผล</div>
           </div>

           <div className="d-flex flex-column justify-content-end mb-3">
             <div className="line-horizontal"></div>
           </div>
         </div>
       </div>

       <div className="flex-basis-35">
         <div className="call-drone">
           <div className="d-flex justify-content-between">
             <div className="ps-3 pt-3 pb-3">
               <div className="mb-2">
                 <div className="fw-medium text-nowrap" style={{fontSize: 18}}>เรียกโดรนเพื่อทำการถ่ายรูป</div>
                 <div className="text-nowrap fw-normal" style={{fontSize: 14}}>วิเคราะห์การตัดแต่งช่อผลลำไย</div>
               </div>

               <div className="call-detail-box mb-2 mt-2">
                 <div className="icon-box me-2">
                   <img src={phoneVolumeIcon} alt="phone volume icon"/>
                 </div>
                 <span className="main-blue-color text-nowrap">098-956-1546</span>
               </div>

               <div className="call-detail-box mb-2 mt-2">
                 <div className="icon-box me-2">
                   <img src={phoneVolumeIcon} alt="phone volume icon"/>
                 </div>
                 <span className="main-blue-color text-nowrap">062-462-3555</span>
               </div>

               <div className="call-detail-box mb-2 mt-2">
                 <div className="icon-box me-2">
                   <img src={phoneVolumeIcon} alt="phone volume icon"/>
                 </div>
                 <span className="main-blue-color text-nowrap">099-803-8173</span>
               </div>
             </div>

             <div>
               <img src={droneLonganImg} alt="drone image" className="w-100 h-100"/>
             </div>
           </div>
         </div>

         <div className="d-flex justify-content-center align-items-center w-100 mt-3">
           <button type="button" className="btn next-btn w-100 btn-call-drone" onClick={() => user.type === 'ADMIN' ? navigate('/farmer') : navigate('/home')}>
             <span className="me-2">เรียกโดรน</span>
             <svg xmlns="http://www.w3.org/2000/svg" width="25" height="13" viewBox="0 0 25 13" fill="none">
               <path d="M5.625 0.25C5.9375 0.25 6.25 0.5625 6.25 0.875V1.5H10.625C10.9375 1.5 11.25 1.8125 11.25 2.125C11.25 2.47656 10.9375 2.75 10.625 2.75H0.625C0.273438 2.75 0 2.47656 0 2.125C0 1.8125 0.273438 1.5 0.625 1.5H5V0.875C5 0.5625 5.27344 0.25 5.625 0.25ZM19.375 0.25C19.6875 0.25 20 0.5625 20 0.875V1.5H24.375C24.6875 1.5 25 1.8125 25 2.125C25 2.47656 24.6875 2.75 24.375 2.75H14.375C14.0234 2.75 13.75 2.47656 13.75 2.125C13.75 1.8125 14.0234 1.5 14.375 1.5H18.75V0.875C18.75 0.5625 19.0234 0.25 19.375 0.25ZM5 6.5V4H6.25V6.1875L6.71875 6.46094L8.71094 5.17188C9.80469 4.42969 11.1328 4 12.5 4C13.8281 4 15.1562 4.42969 16.25 5.17188L18.2422 6.46094L18.75 6.1875V4H20V6.5C20 6.73438 19.8828 6.92969 19.6875 7.04688L17.4219 8.45312L17.7344 8.84375C18.3594 9.625 18.75 10.6016 18.75 11.6172V12.125C18.75 12.4766 18.4375 12.75 18.125 12.75C17.7734 12.75 17.5 12.4766 17.5 12.125V11.6172C17.5 10.875 17.2266 10.1719 16.7578 9.625L16.3672 9.11719L14.6875 10.1719C14.5703 10.25 14.4531 10.25 14.375 10.25H10.625C10.5078 10.25 10.3906 10.25 10.2734 10.1719L8.63281 9.11719L8.20312 9.625C7.73438 10.1719 7.5 10.875 7.5 11.6172V12.125C7.5 12.4766 7.1875 12.75 6.875 12.75C6.52344 12.75 6.25 12.4766 6.25 12.125V11.6172C6.25 10.6016 6.60156 9.625 7.22656 8.84375L7.53906 8.45312L5.27344 7.04688C5.07812 6.92969 5 6.73438 5 6.5ZM17.0703 7.20312L15.5859 6.1875C14.6484 5.60156 13.5938 5.25 12.5 5.25C11.3672 5.25 10.3125 5.60156 9.375 6.1875L7.89062 7.20312L10.7812 9H14.1797L17.0703 7.20312Z" fill="#BEBEBE"/>
             </svg>
           </button>
         </div>
       </div>
     </div>
    </Page>
  )
}

export default LonganTreeDrone;
