import Swal from "sweetalert2";

const defaultConfig = {
  confirmButtonText: 'ตกลง',
}
export const unCancelableOptions = {
  allowEscapeKey: false,
  showCloseButton: false,
  allowOutsideClick: false,
}

export const alertSuccess = (title='บันทึกข้อมูลสำเร็จ', text = '') => {
  Swal.fire({
    icon: 'success',
    title,
    text,
    showConfirmButton: false,
    timer: 3000,
    ...defaultConfig,
  })
}

export const alertError = (title = 'บันทึกข้อมูลไม่สำเร็จ', text='', success = null) => {
  Swal.fire({
    icon: 'error',
    title,
    text,
    ...defaultConfig,
    cancelButtonColor: '#FDD1D9',
    confirmButtonColor: '#0E5590'
  }).then(result => {
    if(result.isConfirmed) {
      success && success()
    }
  })
}

export const showProgress = (title='กำลังดำเนินการ', text='') => {
  Swal.fire({
    title,
    text,
    didOpen () {
      Swal.showLoading(null)
    },
    ...unCancelableOptions
  })
}

export const confirmPopupText = (title = '', text= '', success=null) => {
  Swal.fire({
    title,
    text,
    showCancelButton: true
  }).then(result => {
    if(result.isConfirmed) {
      success && success()
    }
  })
}

export const confirmPopupHTML = ({title = '', html= '', isShowConfirm = true, success=null, isAllowOutsideClick= false}) => {
  Swal.fire({
    html,
    title,
    showConfirmButton: isShowConfirm,
    allowOutsideClick: isAllowOutsideClick
  }).then(result => {
    if(result.isConfirmed) {
      success && success()
    }
  })
}

export const dismissPopup = () => {
  Swal.close()
}
