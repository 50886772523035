import {useEffect, useRef, useState} from "react";
import {dismissPopup, showProgress} from "../../components/AlertDialogs";
import {debounce} from "lodash";
import {useNavigate, useParams} from "react-router-dom";
import '../../assets/css/longans/longan_tree_list.css'
import {getLonganList} from "../../api/LonganApi";
import defaultLonganImg from "../../assets/images/farm-not-found.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faPlus, faSearch} from "@fortawesome/free-solid-svg-icons";
import {faPagelines} from "@fortawesome/free-brands-svg-icons";

function LonganTreeList({farm_id, notAddTree=false, setFarmProps = null, containerStyle= {}}) {
  const [search, setSearch] = useState('');
  const [longans, setLongan] = useState([]);
  const [tabs, setTab] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [farmId, setFarmID] = useState('');
  const routeParams = useParams();
  const navigate = useNavigate();
  const stateFarmId = useRef(null)

  useEffect(() => {
    let id = farm_id || routeParams.id
    setFarmID(id)

    if(!stateFarmId.current || stateFarmId.current !== id) {
      let el = document.getElementById('search-longan');
      setSearch('')
      if(el) el.value = ''

      getLonganListInFarm('', id)
      stateFarmId.current = id
    }

    const tabs = [
      {value: '', label: 'ทั้งหมด'},
      {value: 'TRIMMING', label: 'ตัดแต่งช่อผล'},
      // {value: 'DRUG', label: 'ฉีดยาพ่นดอก'},
      // {value: 'LACK_FERTILIZER', label: 'ขาดปุ๋ย'}
    ]
    // setTab(tabs)

  }, [farm_id, farmId, activeTab]);

  const debounceSearch = debounce((v) => {
    setSearch(v)
    getLonganListInFarm(v, farmId)
  }, 1000)

  function getLonganListInFarm(search='', stateId=null) {
    showProgress('')
    let filter = {
      search,
      // type: activeTab
    }

    // console.log('farmId >>>> ', farmId)
    if(stateId) {
      getLonganList(stateId, filter)
        .then(res => {
          if(res.data && res.data.success) {
            const data = res.data.data.longans;
            const farm = res.data.data.farm;
            setLongan(data || [])
            setFarmProps && setFarmProps(farm)
          }
        }).finally(() => {
        dismissPopup()
      })
    }
  }

  function getTypeInnovation(type){
    let typeOfInnovation = tabs.filter((tab) => tab.value === type);
    if(typeOfInnovation && typeOfInnovation.length > 0) {
      return typeOfInnovation[0].label;
    }
  }

  function handleChangeFilter(tab) {
    setActiveTab(tab.value)
  }

  return (
    <div className="longan-tree-container mt-3" style={containerStyle}>
      <div className="input-group mb-4 mt-2">
          <span className="input-group-text rounded-start-pill"
                id="search">
            <FontAwesomeIcon className="main-color" icon={faSearch} />
          </span>
        <input type="search"
               className="form-control search-longan-tree rounded-end-pill"
               placeholder="ค้นหา"
               aria-label="search"
                // value={search}
              id="search-longan"
               onChange={(e) =>  debounceSearch(e.target.value)}
               aria-describedby="search"/>
      </div>

      {/*<div className="d-flex align-items-center pt-4 pb-4" style={{overflowY: 'hidden'}}>
        {
          tabs.map((tab) => (
            <div className={`badge-tab me-2 ${activeTab === tab.value ? 'badge-active' : ''}`}
                 key={`tab-${tab.value}`}
                 onClick={() => handleChangeFilter(tab)}>
              {tab.label}
            </div>
          ))
        }
      </div>*/}

      {
        longans && longans.length > 0 ?
          <div className="longan-tree-list">
            {
              longans.map((longan, index) => (
                <div className="longan-box mb-2 w-100" key={`longan-${index}`}>
                  <div className="d-flex align-items-center w-100" style={{width: '65%'}}>
                    <div style={{width: '25%'}}>
                      <div className="longan-img-box me-3">
                        <img src={longan.full_picture_path || defaultLonganImg} alt="longan image" className="w-100 h-100"/>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center" style={{width: '75%'}}>
                      <div style={{width: '55%'}}>
                        <div className="d-flex flex-column justify-content-between">
                          <p className="fw-bold mb-0 text-nowrap overflow-hidden text-ellipsis" style={{fontSize: 18}}>
                            {longan.name}
                          </p>

                          <div className={`badge-type-innovation ${longan.type && longan.type.toLowerCase()}-innovation`}>
                            <FontAwesomeIcon icon={faPagelines} style={{color: '#46AD4A'}}/>&nbsp;
                            ตัดแต่งช่อผล
                            {/*{getTypeInnovation(longan.type)}*/}
                          </div>
                        </div>
                      </div>

                      <div className="text-nowrap pe-3" style={{width: '45%', textAlign: 'right'}}>
                        <button className="btn btn-submit"
                                style={{padding: '5px 14px', fontSize: 12}}
                                onClick={() => navigate(`/longan/${longan.id}`)}>
                          <span className="fw-bold me-2 text-nowrap">ติดตามผล</span>
                          <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
          : <div className="data-not-found">ไม่พบรายการต้นลำไยในสวน</div>
      }

      {
        !notAddTree &&
        <div className="add-longan-box" onClick={() => navigate(`/farm/${farmId}/longan/add`)}>
          <div className="label-add-longan main-color me-1">เพิ่มต้นลำไย</div>
          <div className="btn-add-longan">
            <FontAwesomeIcon icon={faPlus}/>
          </div>
        </div>
      }
    </div>
  )
}

export default LonganTreeList;
