import Page from "../layouts/Page";
import '../../assets/css/home.css'
import {useEffect, useState} from "react";
import FarmList from "./FarmList";
import LonganTreeList from "../longan_tree/LonganTreeList";
import {dismissPopup, showProgress} from "../../components/AlertDialogs";
import {getFarmList} from "../../api/FarmsApi";
import EmptyListFarm from "../farm/EmptyListFarm";
import {store} from "../../redux/store";

function Home() {
  const [farm_id, setFarmID] = useState(null);
  const [farms, setFarms] = useState([]);

  useEffect(() => {
    let user = store.getState() && store.getState().app && store.getState().app.user;
    // console.log('user >>> ', user)
    showProgress('')
    getFarmList(user.id)
      .then(res => {
        if(res.data && res.data.success) {
          setFarms(res.data.data);
          if(setFarmID && res.data.data.length > 0) {
            setFarmID(res.data.data[0].id)
          }
        }
      }).finally(() => {
      dismissPopup()
    })
  }, []);

  return (
    <Page header={{title: 'OPEN INNOVATION', showRightSide: true, showNoti: true, showLogout: true}}
          footer={{display: true}}
          content={{style: {overflowY: 'hidden'}}}
    >
      <div className="innovation-container h-100">
        {
          farms && farms.length > 0 ?
            <div className="list-container h-100">
              <div className="d-flex flex-column h-100">
                <FarmList setFarmID={(id) => setFarmID(id)} farms={farms}/>
                <LonganTreeList farm_id={farm_id} notAddTree={true} containerStyle={{overflowY: 'scroll'}}/>
              </div>
            </div>
            : <EmptyListFarm/>
        }

      </div>
    </Page>
  )
}

export default Home;
