import '../../assets/css/farmers/farmer_longan_tree_list.css'
import {debounce} from "lodash";
import {useEffect, useState} from "react";
import {alertError, alertSuccess, confirmPopupText, dismissPopup, showProgress} from "../../components/AlertDialogs";
import {useNavigate, useParams} from "react-router-dom";
import Page from "../layouts/Page";
import {getLonganList, removeLongan} from "../../api/LonganApi";
import defaultLonganImg from "../../assets/images/farm-not-found.png"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faPlus, faSearch, faTrash} from "@fortawesome/free-solid-svg-icons";

function FarmerLonganList() {
    const [search, setSearch] = useState('');
    const [longans, setLongan] = useState([]);
    const routeParams = useParams();
    const [farms, setFarm] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        getFarmData()
    }, []);

    function getFarmData(search = '') {
        showProgress('')
        let farm_id = routeParams.farm_id

        getLonganList(farm_id, {search})
          .then(res => {
              if (res.data && res.data.success) {
                  const data = res.data.data.longans;
                  setLongan(data || [])
                  setFarm(res.data.data.farm)
              }
          }).finally(() => {
            dismissPopup()
        })
    }

    const debounceSearch = debounce((v) => {
        setSearch(v)
        getFarmData(v)
    }, 800)

    const deleteLongan = (longan) => {
        confirmPopupText('', `คุณต้องการลบ "${longan.name}" ใช่หรือไม่?`, () => {
            showProgress('')
            removeLongan(longan.id)
              .then(res => {
                  if (res.data && res.data.success) {
                      alertSuccess('ลบข้อมูลสำเร็จ')
                      setTimeout(() => {
                          showProgress('')
                          setTimeout(() => {
                              getFarmData()
                          }, 800)
                      }, 1000)
                  } else {
                      alertError('ลบข้อมูลไม่สำเร็จ', res.data && res.data.message || '')
                  }
              })
        })
    }

    return (
      <Page header={{title: farms && farms.name || '', showLeftSide: true}}>
          <div className="farmer-longan-tree-container mt-3">
              <div className="main-blue-color d-flex align-items-center mb-3">
                  <div className="vertical-line me-1"></div>
                  ต้นลำไยทั้งหมด
              </div>

              <div className="input-group mt-2 mb-2">
          <span className="input-group-text rounded-start-pill"
                id="search">
            <FontAwesomeIcon icon={faSearch} className="main-color"/>
          </span>
                  <input type="search"
                         className="form-control search-longan-tree rounded-end-pill"
                         placeholder="ค้นหา"
                         aria-label="search"
                         onChange={(e) => debounceSearch(e.target.value)}
                         aria-describedby="search"/>
              </div>

              {
                  longans && longans.length > 0 ?
                    <div className="longan-tree-list">
                        {
                            longans.map((longan, index) => (
                              <div className="longan-box w-100 mb-2" key={`longan-${index}`}>
                                  <div className="d-flex align-items-center w-100"
                                       // onClick={() => navigate(`/farmer/${routeParams.id}/farm/${routeParams.farm_id}/longan/${longan.id}`)}
                                  >
                                      <div style={{width: '35%'}}>
                                          <div className="longan-img-box w-100 pe-2">
                                              <img src={longan.full_picture_path || defaultLonganImg} alt="longan image"
                                                   className="w-100 h-100"/>
                                          </div>
                                      </div>

                                      <div style={{width: '65%'}}>
                                          <div className="d-flex align-items-center w-100 justify-content-between">
                                              <div className="fw-bold mb-1" style={{fontSize: 18}}>
                                                  <p
                                                    className="mb-0 text-nowrap overflow-hidden text-ellipsis">{longan.name}</p>
                                              </div>

                                              <div style={{width: '15%'}} className="text-nowrap p-2">
                                                  <div style={{
                                                      padding: '5px 9px',
                                                      borderRadius: 12,
                                                      background: '#FF83831A',
                                                      marginBottom: 10
                                                  }}
                                                       onClick={() => deleteLongan(longan)}>
                                                      <FontAwesomeIcon icon={faTrash}
                                                                       style={{color: '#D73D47', fontSize: 15}}/>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="d-flex justify-content-between align-items-center pb-2">
                                              <button className="btn btn-primary mt-1"
                                                      style={{padding: '5px 14px !important', fontSize: 12, borderRadius: 50, backgroundColor: '#0E5590'}}
                                                      onClick={() => navigate(`/farmer/${routeParams.id}/farm/${routeParams.farm_id}/longan/${longan.id}/detail`)}>
                                                  <span className="fw-bold text-nowrap">ติดตามผล</span>
                                                  {/*<FontAwesomeIcon icon={faArrowRight}/>*/}
                                              </button>

                                              <button className="btn btn-submit"
                                                      style={{padding: '5px 14px', fontSize: 12}}
                                                      onClick={() => navigate(`/farmer/${routeParams.id}/farm/${routeParams.farm_id}/longan/${longan.id}`)}>
                                                  <span className="fw-bold me-2 text-nowrap">เพิ่มรูปลำไย</span>
                                                  <FontAwesomeIcon icon={faArrowRight}/>
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            ))
                        }
                    </div>
                    : <div className="data-not-found">ไม่พบรายการต้นลำไยในสวน</div>
              }

              <div className="add-longan-box"
                   onClick={() => navigate(`/farmer/${routeParams.id}/farm/${routeParams.farm_id}/longan/add`)}>
                  <div className="label-add-longan main-color me-1">เพิ่มต้นลำไย</div>
                  <div className="btn-add-longan">
                      <FontAwesomeIcon icon={faPlus}/>
                  </div>
              </div>
          </div>
      </Page>
    )
}

export default FarmerLonganList;
