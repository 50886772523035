import {Modal, ModalBody} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightFromBracket, faExclamationCircle, faTimes} from "@fortawesome/free-solid-svg-icons";
import logoutImg from "../../assets/images/logout.png";
import {logout} from "../../api/AuthApi";
import {alertError, alertSuccess, showProgress} from "../../components/AlertDialogs";
import {setToken} from "../../api/Api";
import {actionSetAppToken, actionSetUser} from "../../redux/app";
import {useDispatch, useSelector} from "react-redux";
import {liff} from "@line/liff";
import {useNavigate} from "react-router-dom";

const ModalConfirmLogout = ({modalIsOpen, closeModal}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector(state => {
        return state.app && state.app.user;
    })
    let liffId = process.env.REACT_APP_LINE_LIFF_ID;
    const handleLogout = () => {
        showProgress('', 'ระบบกำลังนำท่านออกจากระบบ')
        logout()
            .then(res => {
                if(res.data && res.data.success) {
                    if(user && user.type === 'FARMER' && user.register_type === 'LINE') {
                        liff.init({liffId}).then(() => {
                            liff.logout()
                        })
                    }
                    setToken(null)
                    dispatch(actionSetAppToken(null))
                    dispatch(actionSetUser(null))
                    setTimeout(() => {
                        navigate('/login')
                    }, 800)
                    alertSuccess('ออกจากระบบสำเร็จ')
                }else {
                    alertError('ออกจากระบบไม่สำเร็จ')
                }
        })
    }

    return (
            <Modal isOpen={modalIsOpen}
                   centered={true}
                   scrollable={true}
                   fade={true}>
                <div className="p-3">
                    <ModalBody>
                        <div className="text-center position-relative">
                            <div onClick={() => closeModal()} style={{position: 'absolute', right: 0, top: 0}}>
                                <FontAwesomeIcon icon={faTimes}/>
                            </div>

                            <div>
                                <img src={logoutImg}/>
                            </div>

                            <div style={{width: '100%'}} className="d-flex justify-content-center mt-2">
                                <div style={{background: '#FDD1D9', color: '#ED2227', padding: '5px 10px', borderRadius: 50, fontSize: 18, width: 'fit-content'}}
                                     className="mt-3 fw-bold d-flex align-items-center justify-content-center"
                                >
                                    ยืนยันออกจากระบบ &nbsp;
                                    <FontAwesomeIcon icon={faExclamationCircle}
                                                     style={{fontSize: 30, color: '#ED2227'}}/>
                                </div>
                            </div>

                            <div className="w-100 mt-3">
                                <button className="btn next-btn w-100" style={{fontSize: 18}} onClick={() => handleLogout()}>
                                    <FontAwesomeIcon icon={faArrowRightFromBracket} className="me-2"/>
                                    ออกจากระบบ
                                </button>
                            </div>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
    )
}

export default ModalConfirmLogout;
