import {useEffect, useState} from "react";
import Page from "./layouts/Page";
import "../assets/css/setting.css";
import cloudCheckIcon from "../assets/images/cloud-check.png"
import {getSettingValue, updateSetting} from "../api/SettingApi";
import {alertError, alertSuccess, dismissPopup, showProgress} from "../components/AlertDialogs";
import SetInputNumberForDevice from "../helper/SetInputNumberForDevice";
import KeydownRegexNumber from "../helper/KeydownRegexNumber";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import ModalConfirmLogout from "./layouts/ModalConfirmLogout";
const Setting = () => {
  const [setting, configSettingData] = useState({amount_of_longan: null, distance: null});
  const [showLogout, setShowLogoutConfirm] = useState(false);

  useEffect(() => {
    SetInputNumberForDevice()
  }, []);
  const setData = (key, value) => {
    if(value.length > 2) {
      return
    }else if(parseInt(value) <= 0) {
      let message = key === 'distance' ? 'ระยะห่าง' : 'จำนวนผลลำไย'
      alertError('', `กรุณากรอก${message}ให้ถูกต้อง`)
    }
    configSettingData(exitingData => ({
      ...exitingData,
      [key]: value
    }))
  }



  const submit = () => {
    showProgress('')
    updateSetting(setting)
      .then(res => {
        if(res.data && res.data.success) {
          alertSuccess()
          setTimeout(() => {
            getSetting()
          }, 1000)
        }else {
          alertError('บันทึกข้อมูลไม่สำเร็จ', res.data && res.data.message || '')
        }
      })
  }

  useEffect(() => {
    getSetting()
  }, []);

  const getSetting = () => {
    showProgress('')
    getSettingValue()
      .then(res => {
        if(res.data && res.data.success) {
          configSettingData(res.data.data)
        }
      }).finally(res => {
        dismissPopup()
    })
  }

  return (
    <Page header={{title: 'ตั้งค่า', showLeftSide: true}}>
      <div className="setting-container d-flex justify-content-between flex-column">
        <div>
          <div className="main-blue-color d-flex mb-3 align-items-center">
            <div className="vertical-line me-1"></div>
            ตั้งค่าโมเดล
          </div>

          <div>
            <div className="main-grey-color">จำนวนผลลำไย</div>
            <div className="input-group mb_2rem">
              <input type="number" className="form-control input-credential"
                     placeholder="จำนวนผลลำไย"
                     aria-label="amount_of_longan"
                     onChange={(e) => setData(e.target.name, e.target.value)}
                     value={setting && setting.amount_of_longan}
                     name="amount_of_longan"
                     onKeyDown={KeydownRegexNumber}
                     aria-describedby="amount_of_longan"/>
              <span className="input-group-text main-grey-color" id="amount_of_longan">ลูก</span>
            </div>
          </div>

          <div>
            <div className="main-grey-color">ระยะห่างช่อลำไย</div>
            <div className="input-group mb_2rem">
              <input type="number" className="form-control input-credential"
                     placeholder="ระยะห่างช่อลำไย"
                     aria-label="distance"
                     onChange={(e) => setData(e.target.name, e.target.value)}
                     value={setting && setting.distance}
                     onKeyDown={KeydownRegexNumber}
                     name="distance"
                     aria-describedby="distance"/>
              <span className="input-group-text main-grey-color" id="distance">เซนติเมตร</span>
            </div>
          </div>

        </div>

        <div>
          <button className="btn next-btn w-100" onClick={() => submit()}>
            <img src={cloudCheckIcon} className="me-2"/>
            ตกลง
          </button>

          <button className="btn btn-outline-danger btn-logout w-100 mt-3"
                  onClick={() => setShowLogoutConfirm(true)}>
            <FontAwesomeIcon icon={faArrowRightFromBracket} className="me-2"/>
            ออกจากระบบ
          </button>
        </div>

        {
            showLogout && <ModalConfirmLogout modalIsOpen={showLogout} closeModal={() => setShowLogoutConfirm(false)}/>
        }
      </div>
    </Page>
  )
}

export default Setting;
