import {Modal, ModalBody} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const ModalPreviewImage = ({modalIsOpen, closeModal, src}) => {
  return (
    <Modal isOpen={modalIsOpen}
           centered={true}
           scrollable={true}
           fade={true}
           toggle={() => closeModal()}
           onClosed={() => closeModal()}
           backdrop={true}
    >
      <ModalBody>
        <div className="w-100 h-100">
          <div onClick={() => closeModal()} style={{position: 'absolute', right: 15, top: 15}}>
            <FontAwesomeIcon icon={faTimes}/>
          </div>

          <img src={src} alt="longan predicted image" className="w-100 h-100"/>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalPreviewImage;
