import {get, post} from "./ApiComponent";

export const getpackageData = () => {
  return get('package');
}

export const buyPackage = (package_id) => {
  console.log('data >>>>>> ', package_id)
  return post('package', {id: package_id})
}

export const approveOrRejectPackage = (data) => {
  return post('package/approve', data)
}
