import {useEffect, useState} from "react";
import {getFarmDetailByID, updateProductivity} from "../../api/FarmsApi";
import {useNavigate, useParams} from "react-router-dom";
import {alertError, alertSuccess, dismissPopup, showProgress} from "../../components/AlertDialogs";
import Page from "../layouts/Page";
import defaultFarmImg from "../../assets/images/farm-not-found.png"
import "../../assets/css/productivity/productivity_detail.css"
import KeydownRegexNumber from "../../helper/KeydownRegexNumber";
import CheckCloud from "../../assets/images/check-cloud.png";

function ProductivityDetail () {
  const params = useParams();
  const [data, setData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    showProgress('')
    getFarmDetailByID(params.id)
      .then(res => {
        if(res.data && res.data.success) {
          setData(res.data.data);
          dismissPopup()
        }else {
          alertError('', res.data && res.data.message || '')
        }
      })
  }, []);

  const setQuantity = (value) => {
    setData(existingData => ({
      ...existingData,
      productivity: value
    }))
  }

  const submitUpdateProductivity = () => {
    if(data && !data.productivity) {
      alertError('บันทึกข้อมูลไม่สำเร็จ','กรุณาระบุปริมาณผลผลิต')
      return
    }

    showProgress('')
    updateProductivity(data.id, data.productivity)
      .then(res => {
        if(res.data && res.data.success) {
          alertSuccess()
          setTimeout(() => {
            navigate(-1)
          }, 800)
        }else {
          let message = (res.data && res.data.message) || ''
          alertError('บันทึกข้อมูลไม่สำเร็จ', message)
        }
      })
  }

  return (
    <Page header={{title: 'ปริมาณผลผลิต', showLeftSide: true}}
          footer={{}}
    >
      <div className="productivity-detail-container">
        <div className="farm-detail-container p-3">
          <div className="main-blue-color d-flex align-items-center mb-3">
            <div className="vertical-line me-1"></div>
            {data && data.name}
          </div>

          <div className="w-100 farm-img-box">
            <img className="w-100 h-100" src={data && data.full_picture_path || defaultFarmImg}/>
          </div>

          <div className="detail-box">
            <div className="col-12 pt-3 d-flex">
              <div className="col-4 title-grey-color text-nowrap">ชื่อสวน : </div>
              <div className="col-8">{data && data.name}</div>
            </div>
            <div className="col-12 pt-3 d-flex">
              <div className="col-4 title-grey-color text-nowrap">ตำแหน่งสวน : </div>
              <div className="col-8">{data && data.location}</div>
            </div>
            <div className="col-12 pt-3 d-flex">
              <div className="col-4 title-grey-color text-nowrap">ขนาดพื้นที่ : </div>
              <div className="col-8">
                {data && data.amount_of_rai ? data.amount_of_rai + ' ไร่ ' : ''}
                {data && data.amount_of_square_wa ? data.amount_of_square_wa + ' ตร.ว.' : ''}
              </div>
            </div>
            <div className="col-12 pt-3 d-flex">
              <div className="col-4 title-grey-color text-nowrap">จำนวนต้น : </div>
              <div className="col-8">{data && data.amount_of_tree} ต้น</div>
            </div>
          </div>
        </div>

        <div className="quantity-box p-2 pt-3">
          <div className="title-grey-color">น้ำหนักผลผลิต</div>
          <div className="input-group pt-2">
            <input className="input-border-light form-control"
                   value={data && data.productivity}
                   name="productivity"
                   placeholder="ระบุ"
                   onKeyDown={KeydownRegexNumber}
                   type="number"
                   onChange={(e) => setQuantity(e.target.value)}
            />
            <span className="input-group-text"
                  id="productivity">
                  กก.
              </span>
          </div>
        </div>

        <div className="d-flex justify-content-center align-items-center w-100 footer-container">
          <button className="next-btn btn w-100 d-flex align-items-center justify-content-center"
                  onClick={() => submitUpdateProductivity()}
                  type="button">
            <img src={CheckCloud} alt={'icon'} className="me-2"/>
            ตกลง
          </button>
        </div>
      </div>
    </Page>
  )
}

export default ProductivityDetail;
