import Page from "./layouts/Page";
import '../assets/css/predict.css';
import predictImage from "../assets/images/predict.png"
function Predict() {
  return (
    <Page header={{title: 'PREDICT', showRightSide: true}}
          footer={{}}
    >
      <div className="predict-container">
        <div className="main-blue-color d-flex mb-2 align-items-center align-self-start">
          <div className="vertical-line me-1"></div>
          พยากรณ์
        </div>

        <div>
          <img src={predictImage} alt="predict image"/>
          <div className="main-green-color fw-bold" style={{fontSize: 20}}>
            ยังไม่มีข้อมูลพยากรณ์
          </div>
        </div>
      </div>
    </Page>
  )
}

export default Predict;
