import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {store} from "../../redux/store";

function Footer() {
  const navigate = useNavigate();
  const [menuActive, setMenuActive] = useState('home');
  const user = store && store.getState().app && store.getState().app.user
  const location = useLocation();
  const footer_routes = ['/home','/productivity','/history','/contact'];
  function handleClickMenu(menu) {
    if(menu === 'home' && user.type === 'ADMIN') {
      navigate('/farmer')
    }else {
      navigate(`/${menu}`)
    }
  }
  function fillColor(menu) {
    return menuActive === menu ? '#0E5590' : '#727178';
  }
  useEffect(() => {
    if(footer_routes.includes(location.pathname) ){
      setMenuActive(location.pathname.replace('/',''))
    }
  }, []);
  return (
    <footer>
      <div className="flex-1 text-center cursor-pointer" onClick={() => handleClickMenu('home')}>
        <svg xmlns="http://www.w3.org/2000/svg" width="75" height="24" viewBox="0 0 75 24" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M36.6861 1.21065C37.0472 0.929784 37.5529 0.929784 37.914 1.21065L46.914 8.21065C47.1576 8.4001 47.3 8.69141 47.3 9V20C47.3 20.7957 46.984 21.5587 46.4214 22.1213C45.8588 22.6839 45.0957 23 44.3 23H30.3C29.5044 23 28.7413 22.6839 28.1787 22.1213C27.6161 21.5587 27.3 20.7957 27.3 20V9C27.3 8.69141 27.4425 8.4001 27.6861 8.21065L36.6861 1.21065ZM29.3 9.48908V20C29.3 20.2652 29.4054 20.5196 29.5929 20.7071C29.7805 20.8946 30.0348 21 30.3 21H44.3C44.5653 21 44.8196 20.8946 45.0072 20.7071C45.1947 20.5196 45.3 20.2652 45.3 20V9.48908L37.3 3.26686L29.3 9.48908Z"
                fill={fillColor('home')}/>
          <path fillRule="evenodd" clipRule="evenodd" d="M33.3 12C33.3 11.4477 33.7478 11 34.3 11H40.3C40.8523 11 41.3 11.4477 41.3 12V22C41.3 22.5523 40.8523 23 40.3 23C39.7478 23 39.3 22.5523 39.3 22V13H35.3V22C35.3 22.5523 34.8523 23 34.3 23C33.7478 23 33.3 22.5523 33.3 22V12Z"
                fill={fillColor('home')}/>
        </svg>
        <div className="mt-2" style={{color: fillColor('home')}}>หน้าหลัก</div>
      </div>

      <div className="flex-1 text-center cursor-pointer" onClick={() => handleClickMenu('productivity')}>
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" viewBox="0 0 21 20" fill="none">
          <g clipPath="url(#clip0_400_428)">
            <path d="M19.2333 14.3421C19.2333 17.0066 17.0386 19.1667 14.3314 19.1667C11.6241 19.1667 9.4294 17.0066 9.4294 14.3421C9.4294 11.6776 11.6241 9.51755 14.3314 9.51755C17.0386 9.51755 19.2333 11.6776 19.2333 14.3421Z"
                  fill="white"/>
            <path d="M12.9588 16.0146C13.1885 16.2735 13.4958 16.454 13.8364 16.53C14.1638 16.6435 14.5228 16.6307 14.8411 16.4942C15.1594 16.3577 15.4133 16.1076 15.5518 15.7943C15.7208 15.2007 15.1152 14.5523 14.3347 14.3653C13.5543 14.1782 12.9512 13.534 13.1176 12.9363C13.2559 12.6228 13.5099 12.3726 13.8282 12.2361C14.1465 12.0996 14.5056 12.0869 14.8331 12.2005C15.17 12.2754 15.4746 12.4525 15.7039 12.7068"
                  fill="white"/>
            <path d="M2.56665 9.51755L7.46861 5.65791L11.3902 6.62282L18.2529 0.833344" fill="white"/>
            <path d="M15.1088 0.833344H18.2529V3.92782" fill="white"/>
            <path d="M12.9588 16.0146C13.1885 16.2735 13.4958 16.454 13.8364 16.53C14.1638 16.6435 14.5228 16.6307 14.8411 16.4942C15.1594 16.3577 15.4133 16.1076 15.5518 15.7943C15.7208 15.2007 15.1152 14.5523 14.3347 14.3653C13.5543 14.1782 12.9512 13.534 13.1176 12.9363C13.2559 12.6228 13.5099 12.3726 13.8282 12.2361C14.1465 12.0996 14.5056 12.0869 14.8331 12.2005C15.17 12.2754 15.4746 12.4525 15.7039 12.7068M14.3727 16.5824V17.1784M14.3727 11.4474V12.1473M2.56665 9.51755L7.46861 5.6579L11.3902 6.62282L18.2529 0.833344M18.2529 0.833344H15.1088M18.2529 0.833344V3.92782M6.48822 18.2018V12.4123M2.56665 18.2018V14.3421M19.2333 14.3421C19.2333 17.0066 17.0386 19.1667 14.3314 19.1667C11.6241 19.1667 9.4294 17.0066 9.4294 14.3421C9.4294 11.6776 11.6241 9.51755 14.3314 9.51755C17.0386 9.51755 19.2333 11.6776 19.2333 14.3421Z"
                  stroke={fillColor('productivity')} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_400_428">
              <rect width="20" height="20" fill="white" transform="translate(0.899963)"/>
            </clipPath>
          </defs>
        </svg>
        <div className="mt-2" style={{color: fillColor('productivity')}}>ปริมาณผลผลิต</div>
      </div>

      {/*<div className="flex-1 text-center cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none">
          <rect x="2.5" y="2" width="52" height="52" rx="26" fill="#0E5590"/>
          <path d="M28.5 32.902C27.61 32.902 26.74 32.6432 25.9999 32.1584C25.2599 31.6737 24.6831 30.9846 24.3425 30.1785C24.0019 29.3724 23.9128 28.4853 24.0865 27.6295C24.2601 26.7737 24.6887 25.9876 25.318 25.3706C25.9474 24.7536 26.7492 24.3334 27.6221 24.1632C28.495 23.993 29.3998 24.0803 30.2221 24.4143C31.0443 24.7482 31.7471 25.3136 32.2416 26.0392C32.7361 26.7647 33 27.6176 33 28.4902C32.9987 29.6599 32.5241 30.7813 31.6805 31.6083C30.8369 32.4354 29.6931 32.9007 28.5 32.902ZM28.5 26.0392C28.0055 26.0392 27.5222 26.183 27.1111 26.4523C26.7 26.7216 26.3795 27.1044 26.1903 27.5522C26.0011 28.0001 25.9516 28.4929 26.048 28.9684C26.1445 29.4438 26.3826 29.8805 26.7322 30.2233C27.0819 30.5661 27.5273 30.7995 28.0123 30.8941C28.4972 30.9886 28.9999 30.9401 29.4567 30.7546C29.9135 30.5691 30.304 30.2549 30.5787 29.8519C30.8534 29.4488 31 28.975 31 28.4902C31 27.8402 30.7366 27.2167 30.2678 26.7571C29.7989 26.2974 29.163 26.0392 28.5 26.0392Z" fill="white"/>
          <path d="M36.5 36.3333H20.5C19.9696 36.3333 19.4609 36.1267 19.0858 35.759C18.7107 35.3913 18.5 34.8926 18.5 34.3725V24.5686C18.5 23.7886 18.8161 23.0405 19.3787 22.4889C19.9413 21.9373 20.7044 21.6275 21.5 21.6275H22.882L23.053 21.2922C23.3007 20.8026 23.6833 20.3908 24.1575 20.1035C24.6318 19.8162 25.1786 19.6649 25.736 19.6667H31.264C31.8214 19.665 32.3682 19.8164 32.8424 20.1036C33.3165 20.3909 33.6992 20.8026 33.947 21.2922L34.118 21.6275H35.5C36.2956 21.6275 37.0587 21.9373 37.6213 22.4889C38.1839 23.0405 38.5 23.7886 38.5 24.5686V34.3725C38.5 34.8926 38.2893 35.3913 37.9142 35.759C37.5391 36.1267 37.0304 36.3333 36.5 36.3333ZM21.5 23.5882C21.2348 23.5882 20.9804 23.6915 20.7929 23.8754C20.6054 24.0592 20.5 24.3086 20.5 24.5686V34.3725H36.5V24.5686C36.5 24.3086 36.3946 24.0592 36.2071 23.8754C36.0196 23.6915 35.7652 23.5882 35.5 23.5882H33.5C33.3142 23.5883 33.132 23.5376 32.9739 23.4419C32.8158 23.3461 32.6881 23.2091 32.605 23.0461L32.158 22.1696C32.0757 22.0063 31.9482 21.8689 31.7902 21.773C31.6321 21.6772 31.4498 21.6268 31.264 21.6275H25.736C25.5502 21.6269 25.3679 21.6774 25.2099 21.7732C25.0519 21.869 24.9244 22.0064 24.842 22.1696L24.4 23.0461C24.3166 23.2098 24.188 23.3474 24.029 23.4432C23.8699 23.539 23.6867 23.5892 23.5 23.5882H21.5Z" fill="white"/>
          <rect x="2.5" y="2" width="52" height="52" rx="26" stroke="white" strokeWidth="4"/>
        </svg>
      </div>*/}

      <div className="flex-1 text-center cursor-pointer" onClick={() => handleClickMenu('history')}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M12.1 3C7.12941 3 3.09998 7.02944 3.09998 12C3.09998 16.9706 7.12941 21 12.1 21C17.0705 21 21.1 16.9706 21.1 12C21.1 7.02944 17.0705 3 12.1 3ZM1.09998 12C1.09998 5.92487 6.02484 1 12.1 1C18.1751 1 23.1 5.92487 23.1 12C23.1 18.0751 18.1751 23 12.1 23C6.02484 23 1.09998 18.0751 1.09998 12Z"
                fill={fillColor('history')}/>
          <path fillRule="evenodd" clipRule="evenodd" d="M12.1 5C12.6523 5 13.1 5.44772 13.1 6V11.382L16.5472 13.1056C17.0412 13.3526 17.2414 13.9532 16.9944 14.4472C16.7474 14.9412 16.1467 15.1414 15.6528 14.8944L11.6528 12.8944C11.314 12.725 11.1 12.3788 11.1 12V6C11.1 5.44772 11.5477 5 12.1 5Z"
                fill={fillColor('history')}/>
        </svg>
        <div className="mt-2" style={{color: fillColor('history')}}>ประวัติ</div>
      </div>

      <div className="flex-1 text-center cursor-pointer" onClick={() => handleClickMenu('contact')}>
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
          <path d="M8.52395 5.937C8.78895 5.937 9.05195 5.833 9.24995 5.625C9.99095 4.844 11.313 4.815 12.085 5.56C12.483 5.944 13.116 5.932 13.499 5.535C13.883 5.137 13.871 4.504 13.474 4.121C12.723 3.397 11.739 3 10.7 3C9.60895 3 8.55195 3.455 7.79895 4.248C7.41895 4.648 7.43595 5.281 7.83595 5.662C8.02895 5.846 8.27695 5.937 8.52395 5.937Z"
                fill={fillColor('contact')}/>
          <path d="M17.7 7C17.7 3.141 14.559 0 10.7 0C6.84095 0 3.69995 3.141 3.69995 7C2.04595 7 0.699951 8.346 0.699951 10V12C0.699951 13.654 2.04595 15 3.69995 15H4.69995C5.25295 15 5.69995 14.553 5.69995 14V7C5.69995 4.243 7.94295 2 10.7 2C13.457 2 15.7 4.243 15.7 7V14.083C15.7 15.691 14.391 17 12.783 17H12.7C12.7 15.897 11.803 15 10.7 15H9.69995C8.59695 15 7.69995 15.897 7.69995 17V18C7.69995 19.103 8.59695 20 9.69995 20H10.7C11.437 20 12.075 19.595 12.422 19H12.783C15.18 19 17.176 17.273 17.607 15H17.7C19.354 15 20.7 13.654 20.7 12V10C20.7 8.346 19.354 7 17.7 7ZM3.69995 13C3.14795 13 2.69995 12.552 2.69995 12V10C2.69995 9.448 3.14795 9 3.69995 9V13ZM10.7 18H9.69995V17H10.7V18ZM18.7 12C18.7 12.552 18.252 13 17.7 13V9C18.252 9 18.7 9.448 18.7 10V12Z"
                fill={fillColor('contact')}/>
        </svg>
        <div className="mt-2" style={{color: fillColor('contact')}}>สอบถาม</div>
      </div>
    </footer>
  )
}

export default Footer;
