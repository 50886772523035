import {Route, Routes} from "react-router-dom";
import React from "react";
import Home from "./views/home/Home";
import Predict from "./views/Predict";
import History from "./views/History";
import Contact from "./views/Contact";
import FarmDetail from "./views/farm/FarmDetail";
import CreateFarm from "./views/farm/CreateFarm";
import Farms from "./views/farm/Farms";
import LonganTreeDetail from "./views/longan_tree/LonganTreeDetail";
import LonganTreeDrone from "./views/longan_tree/LonganTreeDrone";
import FarmerList from "./views/farmer/FarmerList";
import FarmerLonganList from "./views/farmer/FarmerLonganList";
import CreateLonganTree from "./views/longan_tree/CreateLonganTree";
import LonganTreePredict from "./views/longan_tree/LonganTreePredict";
import FarmerDetail from "./views/farmer/FarmerDetail";
import ModalGoogleMap from "./components/ModalGoogleMap";
import Setting from "./views/Setting";
import Package from "./views/packages/Package";
import NotificationAdmin from "./views/admin_notifications/NotificationAdmin";
import NotificationDetailAdmin from "./views/admin_notifications/NotificationDetailAdmin";
import NotificationFarmer from "./views/farmer_notification/NotificationFarmer";
import ApproveUser from "./views/users/ApproveUser";
import Productivity from "./views/Productivity/Productivity";
import ProductivityDetail from "./views/Productivity/ProductivityDetail";

function AppRouter() {
  return (
    <Routes>
      <Route exact path="/home" element={<Home/>} />

      {/*admin notification*/}
      <Route exact path="/admin_notification" element={<NotificationAdmin/>}/>
      <Route exact path="/admin_notification/:id" element={<NotificationDetailAdmin/>}/>

      {/*farmer notification*/}
      <Route exact path="/farmer_notification" element={<NotificationFarmer/>}/>

      {/*farmer*/}
      <Route exact path="/farmer" element={<FarmerList/>}/>
      <Route exact path="/farmer/:id" element={<FarmerDetail/>}/>
      <Route exact path="/user/:id" element={<FarmerDetail/>}/>
        <Route exact path="/user/:id/approve" element={<ApproveUser/>}/>
      <Route exact path="/farmer/:id/farm" element={<Farms styleListContainer={{paddingBottom: '5.5rem'}}/>}/>
      <Route exact path="/farmer/:id/farm/:farm_id" element={<FarmerLonganList/>}/>
      <Route exact path="/farmer/:id/farm/:farm_id/longan/:longan_id" element={<CreateLonganTree/>}/>
      <Route exact path="/farmer/:id/farm/:farm_id/longan/:longan_id/detail" element={<LonganTreeDetail/>}/>
      <Route exact path="/farmer/:id/farm/:farm_id/longan/:longan_id/predict" element={<LonganTreePredict/>}/>
      <Route exact path="/farmer/:id/farm/:farm_id/longan/:longan_id/drone" element={<LonganTreeDrone/>}/>
      <Route exact path="/farmer/:id/farm/:farm_id/longan/add" element={<CreateLonganTree/>}/>
      {/*admin*/}
      <Route exact path="/farm" element={<Farms/>}/>
      <Route exact path="/farm/add" element={<CreateFarm/>}/>
      <Route exact path="/farm/:id" element={<FarmDetail/>}/>
      <Route exact path="/farm/:id/longan/add" element={<CreateLonganTree/>}/>
      <Route exact path="/package" element={<Package/>}/>
      <Route exact path="/longan/:id" element={<LonganTreeDetail/>}/>
      <Route exact path="/longan/:id/drone" element={<LonganTreeDrone/>}/>
      <Route exact path="/longan/:id/predict" element={<LonganTreePredict/>}/>
      {/*<Route exact path="/predict" element={<Predict/>}/>*/}
      <Route exact path="/productivity" element={<Productivity/>}/>
      <Route exact path="/productivity/:id" element={<ProductivityDetail/>}/>
      <Route exact path="/history" element={<History/>}/>
      <Route exact path="/contact" element={<Contact/>}/>
      <Route exact path="/setting" element={<Setting/>}/>
      <Route exact path="/farm/google_map" element={<ModalGoogleMap/>}/>
    </Routes>
  )
}

export default AppRouter;
