import {useEffect, useState} from "react";
import Page from "../layouts/Page";
import ImageDefault from '../../assets/images/farm-not-found.png'
import '../../assets/css/longans/longan_tree_detail.css'
import seedBagIcon from '../../assets/images/seed-bag.png'
import CheckCloud from "../../assets/images/check-cloud.png";
import {useNavigate, useParams, useRoutes} from "react-router-dom";
import {getLonganDetail} from "../../api/LonganApi";
import {dismissPopup, showProgress} from "../../components/AlertDialogs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import MaintenanceLonganTreeSuggestion from "./MaintenanceLonganTreeSuggestion";
import TrimmingLonganTreeSuggestion from "./TrimmingLonganTreeSuggestion";
import {useSelector} from "react-redux";

function LonganTreeDetail() {
  const [longan, setLongan] = useState();
  const [predictedImage, setPredictedImage] = useState([]);
  const navigate = useNavigate();
  const routeParam = useParams();
  const [id, setId] = useState();
  const farm_id = routeParam.farm_id || null
  const longan_id = routeParam.longan_id || null

  const user = useSelector(state => {
    return state.app && state.app.user;
  })

  useEffect(() => {
    let longanId = user.type === 'ADMIN' ? routeParam.longan_id : routeParam.id

    if(longanId) {
      showProgress('')
      setId(longanId)
      getLonganDetail(longanId)
        .then(res => {
          if(res.data && res.data.success) {
            setLongan(res.data.data)
            if(res.data.data.predicted_picture) {
              setPredictedImage(res.data.data.predicted_picture)
            }
          }
        }).finally(() => {
          dismissPopup()
      })
    }
  }, []);

  function getNextRoute () {
    if(user.type === 'ADMIN') {
      if(predictedImage.length > 0) {
        return navigate(`/farmer/${routeParam.id}/farm/${farm_id}/longan/${longan_id}/predict`)
      }else {
        return navigate(`/farmer/${routeParam.id}/farm/${farm_id}/longan/${longan_id}/drone`)
      }
    }else {
      return predictedImage.length > 0 ? navigate(`/longan/${id}/predict`) : navigate(`/longan/${id}/drone`)
    }
  }

  return (
    <Page header={{title: (longan && longan.name) || 'OPEN INNOVATION', showLeftSide: true}}
          footer={{}}
    >
      <div className="longan-tree-detail-container">
        <div className="main-blue-color d-flex align-items-center mb-3">
          <div className="vertical-line me-1"></div>
          วิธีการดูแลต้นลำไย
        </div>

        <div className="longan-tree-image-preview w-100 mb-2">
          <img src={longan && longan.pictures && longan.pictures.length > 0 && longan.pictures[0].full_picture_path || ImageDefault}
               className="w-100"/>
        </div>

        <TrimmingLonganTreeSuggestion/>
        {/*<MaintenanceLonganTreeSuggestion/>*/}

        <div className="w-100">
          <button className="btn next-btn w-100 d-flex align-items-center justify-content-center"
                  onClick={() => getNextRoute()}>
            ถัดไป &nbsp;&nbsp;
            <FontAwesomeIcon icon={faChevronRight} style={{padding: '5px 8px', border: '2px solid white', borderRadius: 50}}/>
          </button>
        </div>

        {/*<div className="mb-3 d-flex justify-content-center w-100">
          <div className="badge-water w-100" style={{textAlign: 'center', borderRadius: 10, fontSize: 14}}>
            เมื่อท่านตัดแต่งช่อผลเสร็จสิ้น  กดถ่ายรูป<br/>
            เพื่อวิเคราะห์การตัดแต่งผลลำใยของท่าน และบันทึกผล
          </div>
        </div>

        <div className="d-flex justify-content-center align-items-center w-100">
          <button className="btn-submit btn w-100 d-flex align-items-center justify-content-center"
                  type="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19" fill="none">
              <path d="M6.125 10.75C6.125 8.36719 8.07812 6.375 10.5 6.375C12.8828 6.375 14.875 8.36719 14.875 10.75C14.875 13.1719 12.8828 15.125 10.5 15.125C8.07812 15.125 6.125 13.1719 6.125 10.75ZM10.5 7.625C8.74219 7.625 7.375 9.03125 7.375 10.75C7.375 12.5078 8.74219 13.875 10.5 13.875C12.2188 13.875 13.625 12.5078 13.625 10.75C13.625 9.03125 12.2188 7.625 10.5 7.625ZM14.6406 2.03906L15.0703 3.25H18C19.3672 3.25 20.5 4.38281 20.5 5.75V15.75C20.5 17.1562 19.3672 18.25 18 18.25H3C1.59375 18.25 0.5 17.1562 0.5 15.75V5.75C0.5 4.38281 1.59375 3.25 3 3.25H5.89062L6.32031 2.03906C6.55469 1.29688 7.25781 0.75 8.07812 0.75H12.8828C13.7031 0.75 14.4062 1.29688 14.6406 2.03906ZM3 4.5C2.29688 4.5 1.75 5.08594 1.75 5.75V15.75C1.75 16.4531 2.29688 17 3 17H18C18.6641 17 19.25 16.4531 19.25 15.75V5.75C19.25 5.08594 18.6641 4.5 18 4.5H14.1719L13.4688 2.42969C13.3906 2.19531 13.1562 2 12.8828 2H8.07812C7.80469 2 7.57031 2.19531 7.49219 2.42969L6.78906 4.5H3Z" fill="white"/>
            </svg>&nbsp;&nbsp;
            ถ่ายรูปบันทึกผลตัดแต่งช่อผล
          </button>
        </div>*/}
      </div>
    </Page>
  )
}

export default LonganTreeDetail;
