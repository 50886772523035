import Page from "./layouts/Page";
import "../assets/css/contact.css"
import logoImg from "../assets/images/logo-charn-agri.png"
import phoneCallImg from "../assets/images/phone-volume.png"
import contactImg from "../assets/images/contact.png"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot, faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import {faLine} from "@fortawesome/free-brands-svg-icons";

function Contact() {
  return (
    <Page header={{title: 'CONTACT', showRightSide: true}}
          footer={{}}
    >
      <div className="contact-container">
        <div className="main-blue-color d-flex mb-2 align-items-center">
          <div className="vertical-line me-1"></div>
          ติดต่อสอบถาม
        </div>

        <div className="mt-3">
          <div className="info-container p-4">
            <img src={logoImg} alt="logo image"/>
            <div style={{fontSize: 20}} className="fw-bold mt-2">
              <div>หจก.ชาญเกษตรเทค</div>
            </div>

            <div className="mt-3 mb-1 text-left">
              <FontAwesomeIcon icon={faLocationDot} className="me-2 main-icon-color"/>
              <span>เลขที่ 369/39 หมู่ที่ 13 ต.ป่าไผ่ อ.สันทราย จ.เชียงใหม่</span>
            </div>
          </div>
        </div>

        <div className="mt-3 contact-chanel d-flex justify-content-between">
          <div className="ps-3 pt-3 pb-3">
            <div className="fw-bold text-left" style={{fontSize: 18}}>ติดต่อสอบถามได้ที่</div>

            <div className="call-detail-box mb-2 mt-2">
              <div className="icon-box me-2">
                <FontAwesomeIcon icon={faPhone} className="main-icon-color" style={{fontSize: 16}}/>
              </div>
              <span className="main-blue-color text-nowrap">098-956-1546</span>
            </div>

            <div className="call-detail-box mb-2 mt-2">
              <div className="icon-box me-2">
                <FontAwesomeIcon icon={faPhone} className="main-icon-color" style={{fontSize: 16}}/>
              </div>
              <span className="main-blue-color text-nowrap">062-462-3555</span>
            </div>

            <div className="call-detail-box mb-2 mt-2">
              <div className="icon-box me-2">
                <FontAwesomeIcon icon={faPhone} className="main-icon-color" style={{fontSize: 16}}/>
              </div>
              <span className="main-blue-color text-nowrap">099-803-8173</span>
            </div>
          </div>

          <div>
            <img src={contactImg} className="contact image"/>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default Contact;
