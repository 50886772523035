import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Page from "../layouts/Page";
import {getFarmList, removeFarm} from "../../api/FarmsApi";
import {alertError, alertSuccess, confirmPopupText, dismissPopup, showProgress} from "../../components/AlertDialogs";
import '../../assets/css/farms/farms.css'
import TreeIconGreen from '../../assets/images/tree-icon-green.png';
import defaultTreeImg from "../../assets/images/farm-not-found.png"
import EmptyListFarm from "./EmptyListFarm";
import {getFarmerDetail} from "../../api/FarmerApi";
import CardFarmer from "../farmer/CardFarmer";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faLocationDot, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";

function Farms({data, styleListContainer={}}) {
  const [farms, setFarms] = useState([]);
  const [farmer, setFarmer] = useState(null);
  const navigate = useNavigate();
  const routeParams = useParams();
  const [farmerId, setFarmerId] = useState();

  const user = useSelector(state => {
    return state && state.app && state.app.user
  })

  useEffect(  () => {
    let farmer_id = routeParams.id || ''
    setFarmerId(farmer_id);
    showProgress('')
    if(routeParams.id) {
      getFarmerData()
      getFarms()
    }else {
      getFarms()
    }
  }, [data]);

  const getFarmerData = () => {
    getFarmerDetail(routeParams.id)
      .then(res => {
        if(res.data && res.data.success) {
          setFarmer(res.data.data);
        }
      }).finally(res => {
        dismissPopup()
    })
  }

  const getFarms = () => {
    getFarmList(routeParams.id)
      .then(res => {
        if (res.data && res.data.success) {
          setFarms(res.data.data)
        }
      }).finally(res => {
      dismissPopup()
    })
  }

  const deleteFarm = (id) => {
    confirmPopupText('', 'คุณต้องการลบสวนนี้ใช่หรือไม่?', () => {
      showProgress('')
      removeFarm(id)
        .then(res => {
          if(res.data && res.data.success) {
            alertSuccess('ลบข้อมูลสำเร็จ')
            setTimeout(() => {
              showProgress('')
              setTimeout(() => {
                getFarms()
              }, 800)
            }, 1000)
          }else {
            alertError('ลบข้อมูลไม่สำเร็จ', res.data && res.data.message || '')
          }
        })
    })
  }

  return (
    <Page header={{title: 'สวนทั้งหมด', showLeftSide: true}}
          content={{style: {overflowY: 'hidden'}}}
          footer={{}}>

      <div className="farms-container">
        { routeParams && routeParams.id && <CardFarmer farmer={farmer}/> }

        {
          farms && farms.length > 0 ?
            <div className="h-100" style={styleListContainer}>
              <div className="main-blue-color d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="vertical-line me-1"></div>
                  {farmerId ? 'สวนทั้งหมด' : 'สวนของคุณทั้งหมด' }
                </div>

                {/*<button type={"button"}
                  onClick={() => navigate('/farm/add')}
                  className={"btn btn-primary btn-add-farm"}>
            + เพิ่มแปลง
          </button>*/}
              </div>

              <div className="farms-list-container h-100">
                {
                  farms.map((farm, index) => (
                    <div className="farm-box" key={`farms-${index}`}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center w-100"
                             onClick={() => farmerId ? navigate(`/farmer/${farmerId}/farm/${farm.id}`) : navigate(`/farm/${farm.id}`)}
                        >
                          <div style={{width: '30%', marginRight: 10}}>
                            <div className="farm-img-box">
                              <img src={farm.full_picture_path || defaultTreeImg} alt="longan image"
                                   className="w-100 h-100"/>
                            </div>
                          </div>

                          <div style={{padding: '0.8rem', width: '70%'}}>
                            <div>
                              <div className="fw-bold mb-2">
                                <p className="mb-0 text-nowrap overflow-hidden text-ellipsis">
                                  {farm.name}
                                </p>
                              </div>

                              <div style={{fontSize: 12}} className="mb-2">
                                <FontAwesomeIcon icon={faLocationDot} className="main-color"/>
                                <span className="ms-2">{farm.location || '-'}</span>
                              </div>

                              <div className="d-flex align-items-center">
                                <div className="farm-detail d-flex align-items-center me-2" style={{fontSize: 10}}>
                                  <img src={TreeIconGreen} alt={'icon tree'} className="me-1"/>
                                  <span style={{fontSize: 12}}> {farm.amount_of_tree} ต้น </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {
                          farmerId &&
                          <div className="pe-2">
                            <div style={{padding: '5px 9px', borderRadius: 12, background: '#FF83831A'}}
                                 onClick={() => deleteFarm(farm.id)}>
                              <FontAwesomeIcon icon={faTrash} style={{color: '#D73D47', fontSize: 15}}/>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  ))
                }

                {
                  user && user.type === 'FARMER' &&
                  <div className="add-box" onClick={() => navigate('/farm/add')}>
                    <div className="label-add main-color me-1">เพิ่มสวนลำไย</div>
                    <div className="btn-add">
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                  </div>
                }
              </div>
            </div>
            : <EmptyListFarm/>
        }
      </div>
    </Page>
  )
}

export default Farms;
