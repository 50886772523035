import seedBagIcon from "../../assets/images/seed-bag.png";
import {useState} from "react";

const TrimmingLonganTreeSuggestion = () => {
  const [activeTab, setActiveTab] = useState('ทรงเปิดกลางพุ่ม')
  const tabs = [
    {
      tab_name: 'ทรงเปิดกลางพุ่ม',
      title: 'ขั้นตอนการตัดแต่งทรงพุ่ม',
      detail: [
        {label: 'ตัดกิ่งที่อยู่ตรงกลาง 2-5 กิ่ง'},
        {label: 'ตัดกิ่งที่อยู่ด้านในทรงพุ่มที่ไม่ได้รับแสงออก'},
        {label: 'ตัดกิ่งที่ที่มีการทำลายของโรคและแมลง', height: 20},
        {
          label: 'ตัดกิ่งที่ไขว้กัน',
          more_detail: 'ตัดแต่งกิ่งให้เหลือร้อยละ 60 ของทรงพุ่ม'
        }
      ]
    },
    {
      tab_name: 'ทรงสี่เหลี่ยม',
      title: 'ขั้นตอนการตัดแต่งทรงสี่เหลี่ยม',
      detail: [
        {
          label: 'ตัดปลายกิ่งด้านบน',
          more_detail: 'ห้ามตัดลึกจนเกินไปจะทำให้ออกดอกช้า',
          height: 27
        },
        {label: 'ตัดปลายกิ่งด้านข้างออก'}
      ]
    },
    {
      tab_name: 'ทรงฝาชีหงาย/ทรงแบน',
      title: 'ขั้นตอนการตัดแต่งทรงฝาชีหงาย/ทรงแบน',
      detail: [
        {label: 'ตัดกิ่งที่อยู่กลางทรงพุ่มออกให้หมด', height: 22},
        {
          label: 'ตัดกิ่งกระโดงออกเหลือตอยาว 2-5 นิ้ว',
          more_detail: 'ถ้าลดความสูงมากจนเกินไปผลผลิตจะลดลง'
        }
      ]
    }
  ]

  const drug_innovations = [
    {
      title: 'ทางใบ',
      body: [
        '30-20-10',
        '20-20-20'
      ]
    },
    {
      title: 'อัตราส่วน สาร/น้ำ',
      body: [
        '100 cc / 20 L'
      ]
    },
    {
      title: 'การป้องกันโรคและแมลง',
      body: [
        'ไรลำไย',
        'เพี้ยแป้ง',
        'หนอนเจาะกิ่ง',
        'แมลงค่อมทอง',
        'หนอนคืบกินใบอ่อน',
        'หนอนม้วนใบ'
      ]
    },
    {
      title: 'สารป้องกัน กำจัด',
      body: [
        'โมโนโครโตฟอส',
        'คาร์บาริล',
        'เคลเทน'
      ]
    }
  ]

  return (
    <div>
      <div className="mb-2">
        <div className="trimming-detail-box">
          <div className="header">
            ต้นลำไยของคุณทรงอะไร ?
          </div>
          <div className="body p-2">
            <div className="main-blue-color mb-2 d-flex align-items-center overflow-x-auto">
              {
                tabs.map((tab) => (
                  <div className="pt-2 pb-2 flex-1 text-center text-nowrap"
                       onClick={() => setActiveTab(tab.tab_name)}
                       style={{
                         fontSize: 14,
                         borderBottom: activeTab === tab.tab_name ? '2px solid #0E5590' : '2px solid transparent',
                         color: activeTab === tab.tab_name ? '' : '#727178',
                  }}>
                    {tab.tab_name}
                  </div>
                ))
              }
            </div>

            <div className="p-2">
              {
                tabs.map((tab) => (
                  tab.tab_name === activeTab &&
                  <div>
                    <div style={{fontSize: 14, color: '#004B8A'}} className="mb-3">{tab.title}</div>

                    {
                      tab.detail.map((detail, index) => (
                        <div
                          className={`d-flex align-items-center relative ${index < tab.detail.length - 1 ? 'mb-3' : ''}`}>
                          <div className="seq-maintenance-method me-2">
                            {index + 1}
                          </div>
                          <div>
                            <div className="text-black">{detail.label}</div>
                            {detail.more_detail &&
                              <li style={{color: '#E4032E', fontSize: 12}}>{detail.more_detail}</li>}
                          </div>

                          {
                            index < tab.detail.length - 1 &&
                            <div className="vertical-line absolute-line" style={{height: detail.height || ''}}/>
                          }
                        </div>
                      ))
                    }
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>

      <div className="mb-2 mt-3">
        <span style={{color: '#727178'}}>การให้น้ำ</span>
        <div className="rounded-pill badge-water-trimming mt-2">
          ให้น้ำสม่ำเสมอ
        </div>
      </div>

      <div className="mb-2">
        <hr/>
      </div>

      <div className="mb-2">
        <div className="drug-solution d-flex align-items-center">
          <img src={seedBagIcon} alt={'seed bag icon'} className="seed-bag-image"/>
          <span className="ms-2">การใส่ปุ๋ย</span>
        </div>
      </div>

      <div className="mb-2">
        {
          drug_innovations.map((innovation, index) => (
            <div key={index}>

              <div className="mb-3">
                <div className="maintenance-title mb-2">{innovation.title}</div>
                {
                  innovation.body.map((text) => (
                    <li className="mb-1">
                      {text}
                    </li>
                  ))
                }
              </div>

              {
                innovation.title !== 'ทางใบ' &&
                <div className="mb-2">
                  <hr/>
                </div>
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default TrimmingLonganTreeSuggestion;
