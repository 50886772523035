import {Modal, ModalBody} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimes, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import approveUserImg from "../../assets/images/approve_user.png";
import rejectUserImg from "../../assets/images/reject_user.png";
import cloudCheckImg from "../../assets/images/cloud-check.png";
import {alertError, alertSuccess, showProgress} from "../../components/AlertDialogs";
import {approveUser} from "../../api/AuthApi";
import {useNavigate} from "react-router-dom";

const ModalConfirmApproveUser = ({modalIsOpen, closeModal, is_approve, user}) => {
  const navigate = useNavigate();
  const submitApproveOrReject = () => {
    showProgress('')
    let data = {
      id: user.id,
      is_approve
    }

    approveUser(data)
      .then(res => {
        if(res.data && res.data.success) {
          alertSuccess()
          setTimeout(() => {
            navigate(-1)
          }, 500)
        }else {
          alertError()
        }
      })
  }

  return (
    <Modal isOpen={modalIsOpen}
           centered={true}
           scrollable={true}
           fade={true}
    >
      <div className="p-3">
        <ModalBody>
          <div className="text-center position-relative">
            <div onClick={() => closeModal()} style={{position: 'absolute', right: 0, top: 0}}>
              <FontAwesomeIcon icon={faTimes}/>
            </div>

            <div>
              <img src={is_approve ? approveUserImg : rejectUserImg}/>
            </div>

            <div className="mt-3">
              <div className="w-100 d-flex justify-content-center align-items-center">
                {
                  is_approve ?
                    <div className="approve-badge-status badge-status">
                      <span className="me-2">อนุมัติการลงทะเบียน</span>
                      <FontAwesomeIcon icon={faCheckCircle} style={{color: '#03AD54'}}/>
                    </div>
                    :
                    <div className="reject-badge-status badge-status">
                      <span className="me-2">ไม่อนุมัติการลงทะเบียน</span>
                      <FontAwesomeIcon icon={faTimesCircle} style={{color: '#ED2227'}}/>
                    </div>
                }
              </div>
            </div>

            <div className="mt-3">
              <button type="button" className="btn btn-confirm-approve text-white w-100"
                      onClick={() => submitApproveOrReject()}
                      style={{background: '#0E5590', borderRadius: 50, padding: 13}}
              >
                <img src={cloudCheckImg} className="me-2"/>
                ยืนยัน
              </button>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ModalConfirmApproveUser;
