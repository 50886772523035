import Logo from "../assets/images/logo.png"
import LogoCharn from "../assets/images/logo-charn-agri.png"
import '../assets/css/login.css'
import '../assets/fonts/Sarabun/sarabun.css'
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {login, userLineLogin} from "../api/AuthApi";
import {setToken} from "../api/Api";
import {alertError, alertSuccess, dismissPopup, showProgress} from "../components/AlertDialogs";
import {useDispatch} from "react-redux";
import {actionSetAppToken, actionSetUser} from "../redux/app";
import lineLogo from "../assets/images/line-logo.png"
import googleLogo from "../assets/images/google-logo.png"
import {liff} from "@line/liff";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [is_remember, setCheckRemember] = useState(false);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  let liffId = process.env.REACT_APP_LINE_LIFF_ID;

  useEffect(() => {
    if(localStorage.getItem('is_remember')){
      setCheckRemember(localStorage.getItem('is_remember'));
      setEmail(localStorage.getItem('email'));
      setPassword(localStorage.getItem('password'));
    }

    liff.init({liffId}).then(() => {
      if (!!searchParams.get('code') && searchParams.get('state') && !!searchParams.get('liffClientId')) {
        signInLine()
      }
    })
  }, []);

  const signInLine = () => {
    console.log('line login')
    try {
      if (liff.isLoggedIn()) {
        showProgress('')
        getProfile()
      } else {
        liff.login();
      }
    } catch (exception) {
      console.log('exception >>> ', exception)
    }
  }

  const getProfile = async () => {
    try {
      let profile = await liff.getProfile();
      let email = liff.getDecodedIDToken()?.email;

      let credential = {
        name: profile.displayName,
        picture_path: profile.pictureUrl,
        email
      }

      userLineLogin(credential)
        .then(res => {
          resultLogin(res, true)
        })

    } catch (e) {
      console.log('e >>> ', e)
    }
  }

  const submit = () => {
    showProgress('')
    let credentials = {
      email,
      password
    }

    if(is_remember){
      localStorage.setItem('is_remember', true)
      localStorage.setItem('email', email)
      localStorage.setItem('password', password)
    }else {
      localStorage.removeItem('is_remember')
      localStorage.removeItem('email')
      localStorage.removeItem('password')
    }

    login(credentials)
      .then(res => {
        resultLogin(res, false)
      })
  }

  const resultLogin = (res, is_login_line) => {
    console.log('res >>>>>>>>> ', res.data)
    if (res.data && res.data.success) {
      if (res.data.data && res.data.data.token) {
        setToken(res.data.data.token)
        dispatch(actionSetAppToken(res.data.data.token))
        let user = res.data.data.user;
        dispatch(actionSetUser(user))
        alertSuccess('เข้าสู่ระบบสำเร็จ')
        setTimeout(() => {
          dismissPopup()
          if (user.type === 'ADMIN') {
            navigate('/farmer')
          } else {
            navigate('/home')
          }
        }, 1000)
      } else {
        if (is_login_line) {
          alertError('', 'สร้างบัญชีสำเร็จ กรุณาติดต่อผู้ดูแลระบบเพื่อทำการอนุมัติบัญชี')
        } else {
          alertError('เข้าสู่ระบบไม่สำเร็จ', res.data && res.data.message || '')
        }
      }
    } else {
      alertError('เข้าสู่ระบบไม่สำเร็จ', res.data && res.data.message || '')
    }
  }

  return (
    <div className="login_layout">
      <div className="login-header">

      </div>
      <div className="login-container d-flex flex-column justify-content-between">
        <div>
          <div className="text-center">
            <img src={LogoCharn} className="charn-logo" alt={"charn agriculture logo"}/>
          </div>
          <div className="text-center">
            <img src={Logo} alt={"open innovation logo"} style={{width: 239}} className="mb-5 open-innovation-logo"/>
          </div>

          <div className="mb-4">
            <h1 className="main-color fw-bold text-center">เข้าสู่ระบบ</h1>
          </div>

          <div className="mb-4" id="credential-container">
            <div className="mb-2">
              <div className="title-grey-color mb-1">อีเมล</div>
              <div className="input-group mb_2rem">
              <span className="input-group-text" id="email">
                <FontAwesomeIcon icon={faEnvelope} className="main-color"/>
              </span>
                <input type="email" className="form-control input-credential"
                       placeholder="อีเมล"
                       aria-label="email"
                       onChange={(e) => setEmail(e.target.value)}
                       value={email}
                       aria-describedby="email"/>
              </div>
            </div>

            <div>
              <div className="title-grey-color mb-1">รหัสผ่าน</div>
              <div className="input-group mb-3">
              <span className="input-group-text" id="password">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 18 21" fill="none">
                  <path
                    d="M9.625 15.8125C9.625 16.1641 9.3125 16.4375 9 16.4375C8.64844 16.4375 8.375 16.1641 8.375 15.8125V12.6875C8.375 12.375 8.64844 12.0625 9 12.0625C9.3125 12.0625 9.625 12.375 9.625 12.6875V15.8125ZM9 0.5C11.7344 0.5 14 2.76562 14 5.5V8H14.625C16.3438 8 17.75 9.40625 17.75 11.125V17.375C17.75 19.1328 16.3438 20.5 14.625 20.5H3.375C1.61719 20.5 0.25 19.1328 0.25 17.375V11.125C0.25 9.40625 1.61719 8 3.375 8H4V5.5C4 2.76562 6.22656 0.5 9 0.5ZM9 1.75C6.89062 1.75 5.25 3.42969 5.25 5.5V8H12.75V5.5C12.75 3.42969 11.0703 1.75 9 1.75ZM3.375 9.25C2.32031 9.25 1.5 10.1094 1.5 11.125V17.375C1.5 18.4297 2.32031 19.25 3.375 19.25H14.625C15.6406 19.25 16.5 18.4297 16.5 17.375V11.125C16.5 10.1094 15.6406 9.25 14.625 9.25H3.375Z"
                    fill="#0C9447"/>
                </svg>
              </span>
                <input type="password" className="form-control input-credential"
                       placeholder="รหัสผ่าน"
                       aria-label="password"
                       onChange={(e) => setPassword(e.target.value)}
                       value={password}
                       // autoComplete="new-password"
                       aria-describedby="password"/>
              </div>
            </div>

            <div className="d-flex justify-content-between mb-5">
              <div className="remember-password-container">
                <label form="remember_password" className="main-color">
                  <input type="checkbox" value="true"
                         id="remember_password"
                         className="me-1"
                         checked={is_remember}
                         onChange={() => setCheckRemember(!is_remember)}
                         name="remember_password"/>
                  <span className="checkbox-circle"></span>
                  จดจำรหัสผ่าน
                </label>
              </div>

              {/*<div className="title-grey-color cursor-pointer">ลืมรหัสผ่าน?</div>*/}
            </div>

            <div>
              <button type="submit"
                      className="btn btn-primary w-100 d-flex justify-content-center align-items-center"
                      onClick={() => submit()}
                      id="btn-submit">
                <span className="me-1">เข้าสู่ระบบ</span>&nbsp;
                <div style={{marginBottom: 3}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19"
                       fill="none">
                    <path
                      d="M15.5 9.5C15.5 9.85156 15.3438 10.1641 15.1094 10.3984L10.1094 15.3984C9.75781 15.75 9.21094 15.8672 8.74219 15.6719C8.27344 15.4766 8 15.0078 8 14.5V12H2.375C1.32031 12 0.5 11.1797 0.5 10.125V8.875C0.5 7.85938 1.32031 7 2.375 7H8V4.5C8 4.03125 8.27344 3.5625 8.74219 3.36719C9.21094 3.17188 9.75781 3.28906 10.1094 3.64062L15.1094 8.64062C15.3438 8.875 15.5 9.1875 15.5 9.5ZM9.25 14.5L14.2109 9.5L9.21094 4.5L9.25 8.25H2.375C2.02344 8.25 1.75 8.5625 1.75 8.875V10.125C1.75 10.4766 2.02344 10.75 2.375 10.75H9.25V14.5ZM17.375 0.75C19.0938 0.75 20.5 2.15625 20.5 3.875V15.125C20.5 16.8828 19.0938 18.25 17.375 18.25H13.625C13.2734 18.25 13 17.9766 13 17.625C13 17.3125 13.2734 17 13.625 17H17.375C18.3906 17 19.25 16.1797 19.25 15.125V3.875C19.25 2.85938 18.3906 2 17.375 2H13.625C13.2734 2 13 1.72656 13 1.375C13 1.0625 13.2734 0.75 13.625 0.75H17.375Z"
                      fill="white"/>
                  </svg>
                </div>
              </button>
            </div>

            <div className="mt-2 mb-2 w-100 d-flex justify-content-center">
              <hr className="w-25"/>
            </div>

            <div className="mb-2">
              <button type="button" className="btn w-100 btn-submit position-relative sso-btn"
                      onClick={() => signInLine()}>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="position-absolute logo">
                    <img src={lineLogo} alt="line logo"/>
                  </div>
                  <div className="fw-semibold">LINE</div>
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="text-center">
          <span className="text-gray cursor-pointer me-2">คุณยังไม่มีบัญชี?</span>
          <span className="main-color cursor-pointer" onClick={() => navigate('/register')}>ลงทะเบียน</span>
        </div>
      </div>
    </div>
  )
}

export default Login;
