import {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {LonganPlatformEntry} from "../api/AuthApi";
import {setToken} from "../api/Api";
import {actionSetAppToken, actionSetUser} from "../redux/app";
import {alertError, alertSuccess, dismissPopup, showProgress} from "../components/AlertDialogs";
import {useDispatch} from "react-redux";

function VerifyLonganPlatform() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    showProgress('', 'กำลังเปิด Web Application NIA MJU')
    let token = searchParams.get('token');
    if(token)
    {
      LonganPlatformEntry(token)
        .then(res => {
          if(res.data && res.data.success){
            if (res.data.data && res.data.data.token) {
              setToken(res.data.data.token)
              dispatch(actionSetAppToken(res.data.data.token))
              let user = res.data.data.user;
              dispatch(actionSetUser(user))
              setTimeout(() => {
                alertSuccess('เข้าสู่ระบบสำเร็จ')
              }, 500)
              setTimeout(() => {
                dismissPopup()
                if (user.type === 'ADMIN') {
                  navigate('/farmer')
                } else {
                  navigate('/home')
                }
              }, 1000)
            }
          }else {
            setTimeout(() => {
              alertError('เข้าสู่ระบบไม่สำเร็จ', res.data && res.data.message || '')
            }, 500)

            /*setTimeout(() => {
              navigate(-1)
            }, 1000)*/
          }
        })
    }
  }, []);
}

export default VerifyLonganPlatform;
