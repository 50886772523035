import Page from "../layouts/Page";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getLonganDetail} from "../../api/LonganApi";
import '../../assets/css/longans/longan_tree_predict.css'
import defaultImg from "../../assets/images/farm-not-found.png"
import trimmingImg from "../../assets/images/trimming.png"
import cloudCheckIcon from "../../assets/images/cloud-check.png"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faMaximize} from "@fortawesome/free-solid-svg-icons";
import ModalPreviewImage from "./ModalPreviewImage";
import {getSettingValue} from "../../api/SettingApi";
import {dismissPopup, showProgress} from "../../components/AlertDialogs";
import {useSelector} from "react-redux";

function LonganTreePredict()
{
  const routeParams = useParams();
  const [longan, setLongan] = useState();
  const [longanPredicted, setLonganPredicted] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [longan_direction, setDirections] = useState();
  const [config, setConfigData] = useState();
  const navigate = useNavigate();
  const farm_id = routeParams.farm_id || null
  const longan_id = routeParams.longan_id || null

  const user = useSelector(state => {
    return state.app && state.app.user;
  })

  useEffect(() => {
    let longanId = user.type === 'ADMIN' ? routeParams.longan_id : routeParams.id;

    if(longanId) {
      showProgress('')
      getLonganDetail(longanId)
        .then(res => {
          if(res.data && res.data.success) {
            setLongan(res.data.data)
            setLonganPredicted(res.data.data.predicted_picture || [])
            setTimeout(() => {
              dismissPopup()
            }, 1000)
          }
        })

      getSettingValue()
        .then(res => {
          if(res.data && res.data.success) {
            setConfigData(res.data.data)
          }
        })
    }
  }, []);

  const renderImage = (direction) => {
    let picture_path = defaultImg;
    longanPredicted.map((item) => {
      if(item.directions === direction) {
        picture_path = item.full_picture_path
      }
    })

    return picture_path;
  }

  const expandImage = (direction) => {
    setShowImage(true)
    setDirections(direction)
  }

  return (
    <Page header={{title: (longan && longan.name) || 'OPEN INNOVATION', showLeftSide: true}}>
      <div className="longan-predict-container">
        <div className="d-flex w-100 align-items-center mb-2">
          <div className="flex-1 position-relative me-2">
            <div className="longan-img-preview">
              <img src={renderImage('north')} alt="longan north direction" id="north_direction"/>
            </div>
            <div className="image-label-direction">
              ทิศเหนือ
            </div>
            <div className="expand-icon" onClick={() => expandImage('north')}>
              <FontAwesomeIcon icon={faMaximize} style={{color: 'white'}}/>
            </div>
          </div>
          <div className="flex-1 position-relative">
            <div className="longan-img-preview">
              <img src={renderImage('south')} alt="longan south direction"/>
            </div>
            <div className="image-label-direction">
              ทิศใต้
            </div>
            <div className="expand-icon" onClick={() => expandImage('south')}>
              <FontAwesomeIcon icon={faMaximize} style={{color: 'white'}}/>
            </div>
          </div>
        </div>

        <div className="d-flex w-100 align-items-center mb-3">
          <div className="flex-1 position-relative me-2">
            <div className="longan-img-preview">
              <img src={renderImage('east')} alt="longan east direction"/>
            </div>
            <div className="image-label-direction">
              ทิศตะวันออก
            </div>
            <div className="expand-icon" onClick={() => expandImage('east')}>
              <FontAwesomeIcon icon={faMaximize} style={{color: 'white'}}/>
            </div>
          </div>
          <div className="flex-1 position-relative">
            <div className="longan-img-preview">
              <img src={renderImage('west')} alt="longan west direction"/>
            </div>
            <div className="image-label-direction">
              ทิศตะวันตก
            </div>
            <div className="expand-icon" onClick={() => expandImage('west')}>
              <FontAwesomeIcon icon={faMaximize} style={{color: 'white'}}/>
            </div>
          </div>
        </div>

        <div className="w-100 d-flex justify-content-center mb-3">
          <div className="badge-predict">
            ได้รับการยืนยันจากผู้เชี่ยวชาญ&nbsp;
            <FontAwesomeIcon icon={faCheckCircle}/>
          </div>
        </div>

        <div className="d-flex justify-content-center align-items-center mb-2">
          <img src={trimmingImg} alt="trimming image"/>
        </div>

        <div style={{color: '#ED2227', fontSize: 20}} className="text-center fw-semibold mb-2">
          ควรตัดแต่งช่อผลลำไย
        </div>

        <div className="w-100 expert-suggestion-box mb-3">
          <div className="header p-3">
            คำแนะนำจากผู้เชี่ยวชาญ
          </div>

          <div className="body p-3">
            <li>กรอบสีเขียว คือ ช่อผลลำไยมากกว่า {config && config.amount_of_longan || 40} ลูก แนะนำให้ตัดครึ่งช่อ</li>
            <li>กรอบสีชมพู คือ ช่อผลลำไยที่อยู่ใกล้กันเกินระยะ {config && config.distance || 15} cm แนะนำให้กิ่งของช่อผลนั้น</li>
          </div>
        </div>

        <button className="btn next-btn w-100" onClick={() => user.type === 'ADMIN' ? navigate(`/farmer/${routeParams.id}/farm`) : navigate('/home')}>
          <img src={cloudCheckIcon} alt="cloud check icon" className="me-2"/>
          ตกลง
        </button>
      </div>

      {
        showImage && <ModalPreviewImage modalIsOpen={showImage}
                                        closeModal={() => setShowImage(false)}
                                        src={renderImage(longan_direction)}
                      />
      }
    </Page>
  )
}

export default LonganTreePredict;
