import {useNavigate} from "react-router-dom";
import {store} from "../../redux/store";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBell,
  faChevronLeft,
  faCog,
  faUserAlt
} from "@fortawesome/free-solid-svg-icons";
import ModalConfirmLogout from "./ModalConfirmLogout";
import {getCountNotification} from "../../api/NotificationApi";

function Header(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [count_noti, setCountNoti] = useState(0);

  useEffect(() => {
    if( store && store.getState() && store.getState().app && store.getState().app.user) {
      setUser(store.getState().app.user)
    }

    countNotification()
  }, []);

  const countNotification = () => {
    getCountNotification()
      .then(res => {
        if(res.data && res.data.success) {
          setCountNoti(res.data.data.count)
        }
      })
  }

  return (
    <header>
      <div onClick={() => navigate(-1)} className="d-flex" style={{width: '22%'}}>
        {
          props.showLeftSide && <div className="d-flex align-items-center" style={{fontSize: 13}}>
            <FontAwesomeIcon icon={faChevronLeft}/>&nbsp;
            <div>
              <div>ย้อน</div>
              <div>กลับ</div>
            </div>
          </div>
        }
      </div>

      <div className="d-flex text-nowrap overflow-hidden justify-content-center" style={{width: '56%'}}>
        <p className="text-nowrap overflow-hidden text-ellipsis mb-0">
          {props.title || ''}
        </p>
      </div>

      <div className="d-flex justify-content-end" style={{width: '22%'}}>
        {
          props.showNoti &&
          <div className="right-side-menu">
            <div className="position-relative">
              <FontAwesomeIcon icon={faBell}
                               onClick={() => user.type === 'ADMIN' ? navigate('/admin_notification') : navigate('/farmer_notification')}
                               style={{fontSize: 15, color: count_noti > 0 ? '#FFD600' : 'white'}}/>

              { count_noti > 0 && <div className="notification-dot"></div> }
            </div>
          </div>
        }

        {
          user && user.type === 'ADMIN' &&
          <div className="ms-2 right-side-menu">
              <FontAwesomeIcon icon={faCog} onClick={() => navigate('/setting')} style={{fontSize: 15}}/>
          </div>
        }

          {
              user && user.type === 'FARMER' && props.showLogout &&
              <div className="ms-2 right-side-menu">
                <FontAwesomeIcon icon={faUserAlt} onClick={() => navigate(`/user/${user.id}`)}/>
              </div>
          }
      </div>
    </header>
  )
}

export default Header;
