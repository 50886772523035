import {
  Autocomplete,
  DrawingManager,
  GoogleMap,
  Marker,
  OverlayView,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Modal, ModalBody} from "reactstrap";
import {alertError} from "./AlertDialogs";

const containerStyle = {
  width: '100vw',
  height: '100vh'
};

const defaultCenter = {
  // lat: 18.759172645755292,
  // lng: 99.03735574789451
  lat: 18.7977228,
  lng: 98.9574443
};


const libraries = ['places', 'drawing']
const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
let polygons = null;
let boundary = [];
let areas = 0;
let markerPosition = null;
const ModalGoogleMap = ({modalIsOpen, closeModal, setPolygon, setArea, setLocation, propPolygons, drawingModes, propMarker, propMarkerName, isCreateFarm}) => {
  const [searchResult, setSearchResult] = useState();
  const [center, setCenter] = useState(defaultCenter);

  let { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
    libraries
  })

  const onLoadDrawing = (drawingManager) => {
    if(propPolygons) {
      const google = window.google;
      setCenter(propPolygons[0])
      polygons = new google.maps.Polygon({ paths: propPolygons }); //polygon format
    }

    if(propMarker) {
      let propMarkerSplit = propMarker.split(',')
      if(propMarkerSplit && propMarkerSplit.length > 0) {
        markerPosition = {
          lat: parseFloat(propMarkerSplit[0]),
          lng: parseFloat(propMarkerSplit[1])
        }
        setCenter(markerPosition)
      }
    }

    if(!propPolygons && !propMarker) {
      navigator.geolocation.getCurrentPosition(({coords}) => {
        setCenter({lat: coords.latitude, lng: coords.longitude})
      })
    }
  }

  const onPolygonComplete = (polygon) => {
    polygon.setMap(null)
    markerPosition = null;
    setLocation('')
    const poly = polygon.getPath()
    const google = window.google;
    let area = google.maps.geometry.spherical.computeArea(poly)
    setArea && setArea(area)
    mapBoundaryToArray(poly)
  };

  const mapBoundaryToArray = (poly) => {
    let bounds = [];
    for (let i = 0; i < poly.length; i++) {
      let point = {
        lat: poly.getAt(i).lat(),
        lng: poly.getAt(i).lng()
      };
      bounds.push(point);
    }
    const google = window.google;
    polygons = new google.maps.Polygon({ paths: bounds }); //polygon format
    setPolygon(bounds) //array lat lng of polygon
  }

  const onMarkerComplete = (marker) => {
    console.log('polygons >>> ', polygons)
    if(polygons){
      markerPosition = marker.getPosition();
      let locationStr = `${parseFloat(markerPosition.lat()).toFixed(6)}, ${parseFloat(markerPosition.lng()).toFixed(6)}`
      const google = window.google;
      let is_inside = google.maps.geometry.poly.containsLocation(markerPosition, polygons)
      if(is_inside) {
        setLocation(locationStr)
      }else {

        alertError('', `กรุณาเลือกพิกัด${isCreateFarm ? 'สวน' : 'ต้นลำไย'}ให้อยู่ในขอบเขต`)
      }
    }else {
      alertError('', 'กรุณาวาดขอบเขตของสวนก่อนเลือกพิกัด')
      // setLocation(locationStr)
    }
  }

  const handleCloseMap = () => {
    closeModal()
  }

  const unMountModal = () => {
    markerPosition = null
    polygons = [];
    areas = 0;
  }

  const onLoadSearchPlace = (autoComplete) => {
    setSearchResult(autoComplete)
  }

  const onPlaceChanged = () => {
    console.log('searchResult .... ', searchResult)
    if (searchResult != null) {
      //variable to store the result
      const place = searchResult.getPlace();
      if(place && place.geometry){
        const latLng = place.geometry.location;
        setCenter(latLng)
      }
    } else {
      alert("Please enter text");
    }
  }

  const options = {
    region: 'th',
    language: 'th',
    componentRestrictions: {
      country: 'th'
    },
  }
  return isLoaded ? (
    <Modal  isOpen={modalIsOpen}
            centered={true}
            scrollable={true}
            fade={true}
            fullscreen={true}
    >
      <GoogleMap
        id="farm-google-map"
        mapContainerStyle={containerStyle}
        center={center}
        zoom={18}
      >
        <DrawingManager
          onLoad={onLoadDrawing}
          onPolygonComplete={onPolygonComplete}
          onMarkerComplete={onMarkerComplete}
          onUnmount={unMountModal}
          options={{
            drawingControlOptions: {
              drawingModes,
              position: window.google.maps.ControlPosition.RIGHT_BOTTOM
            },
            drawingControl: true,
            polygonOptions: {
              clickable: isCreateFarm,
              editable: isCreateFarm,
              draggable: false,
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#FF0000',
              fillOpacity: 0.1,
            },
            markerOptions: {
              visible: false,
            }
          }}
        />

        {
          markerPosition &&
          <Marker position={markerPosition}/>
        }

        {
          markerPosition &&
          <OverlayView mapPaneName="markerLayer" position={markerPosition}>
            <div className="marker-label">
              <div style={{color: 'black', fontSize: 16}} className="mb-2">{propMarkerName}</div>
              <div style={{fontSize: 12, color: '#727178'}}>{propMarker}</div>
            </div>
          </OverlayView>
        }

        {
          propPolygons &&
          <Polygon paths={propPolygons}
                   options={{
                     strokeColor: '#FF0000',
                     strokeOpacity: 0.8,
                     strokeWeight: 2,
                     fillColor: '#FF0000',
                     fillOpacity: 0.1,
                     editable: false,
                     clickable: false
                   }}
          />
        }

        <div style={styleSearchContainer}>
          <Autocomplete
            apiKey={apiKey}
            onPlaceChanged={onPlaceChanged}
            onLoad={onLoadSearchPlace}
            options={options}
            language={'th'}
          >
            <input
              type="text"
              placeholder="ค้นหาสวน"
              style={styleSearchInput}
            />
          </Autocomplete>
        </div>

          <button className="btn btn-success"
              style={{position: 'absolute', bottom: 15, left: '30%', padding: 10, width: '40%', borderRadius: '8px !important'}}
              onClick={() => handleCloseMap()}>เสร็จสิ้น</button>
      </GoogleMap>
    </Modal>

  ) : <Modal isOpen={modalIsOpen}
             centered={true}
             scrollable={true}
             fade={true}
  >
    <ModalBody>
      <div className="text-center main-green-color p-3">
        <h5>ไม่สามารถโหลดข้อมูลแผนที่ได้</h5>
        <h5>กรุณาติดต่อผู้ดูแลระบบ</h5>
      </div>
    </ModalBody>
  </Modal>
}

export default ModalGoogleMap;

const styleSearchInput = {
  boxSizing: `border-box`,
  border: `1px solid #008F44`,
  background: '#DEF7EC',
  width: `100%`,
  height: 50,
  padding: 12,
  borderRadius: 8,
  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
  fontSize: 16,
  outline: `none`,
  textOverflow: `ellipses`
}

const styleSearchContainer = {
  top: 70,
  left: '2.5%',
  position: "absolute",
  zIndex: 1000,
  width: '95%'
}
