import {post, get} from "./ApiComponent";

export function updateSetting(data)
{
  return post('setting', data);
}

export function getSettingValue() {
  return get('setting');
}
