import farmNotFoundImage from "../../assets/images/farm-not-found.png";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

function EmptyListFarm() {
  const navigate = useNavigate();
  return (
    <div className="position-relative h-100 text-center d-grid">
      <div className="main-blue-color d-flex mb-2 align-self-start">
        <div className="d-flex align-items-center">
          <div className="vertical-line me-1"></div>
          สวนของคุณทั้งหมด
        </div>
      </div>

      <div>
        <img src={farmNotFoundImage} alt="farm not found image"/>
        <div className="main-green-color fw-bold" style={{fontSize: 20}}>
          ยังไม่มีข้อมูลสวน
        </div>
      </div>

      <div className="add-box" onClick={() => navigate('/farm/add')} style={{right: 0, bottom: 0}}>
        <div className="label-add main-color me-1">เพิ่มสวนลำไย</div>
        <div className="btn-add">
            <FontAwesomeIcon icon={faPlus}/>
        </div>
      </div>
    </div>
  )
}

export default EmptyListFarm;
