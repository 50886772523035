import Page from "../layouts/Page";
import "../../assets/css/package.css"
import {useEffect, useState} from "react";
import {getpackageData} from "../../api/PackageApi";
import packageImg from "../../assets/images/package.png";
import longanImg from "../../assets/images/longan-package.png"
import ModalPackageConfirm from "./ModalPackageConfirm";
import CardPackage from "./CardPackage";

const Package = () => {
  const [packages, setPackages] = useState([]);
  const [packageSeleted, setPackageSeleted] = useState(null);
  const [showPackage, setShowPackage] = useState(false);

  useEffect(() => {
    getPackage()
  }, [packageSeleted]);

  const getPackage = () => {
    getpackageData()
      .then(res => {
        if (res.data && res.data.success){
          setPackages(res.data.data)
        }
      })
  }

  const showConfirm = (item) => {
    setPackageSeleted(item);
    setShowPackage(true);
  }

  return (
    <Page header={{title: 'เลือกแพ็กเกจ', showLeftSide: true}}>
      <div className="package-container" style={packages.length === 0 ? {display: 'grid', textAlign: 'center'} : {}}>
        <div className="main-blue-color d-flex mb-2 align-items-center align-self-start">
          <div className="vertical-line me-1"></div>
          แพ็กเกจทั้งหมด
        </div>

        {
          packages && packages.length > 0 ?
            <div className="package-list h-100 pb-4">
              {
                packages.map((pk, index) => (
                  <CardPackage item={pk} showConfirm={() => showConfirm(pk)}/>
                ))
              }
            </div>
            : <div>
              <img src={packageImg} alt="history image"/>
              <div className="main-green-color mt-3 fw-bold" style={{fontSize: 20}}>
                ยังไม่มีข้อมูลแพ็กเกจ
              </div>
            </div>
        }
      </div>

      {
        showPackage &&
        <ModalPackageConfirm modalIsOpen={showPackage}
                             closeModal={() => setShowPackage(false)}
                             item={packageSeleted}
        />
      }
    </Page>
  )
}

export default Package;
